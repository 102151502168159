<template>
  <div>
    <div class="main-header">
      <div class="main-header__heading">Recebimento</div>
      <div class="col col-9">
        <button class="btn btn-success">Salvar</button>
        <div class="float-btn">
          <i class="fas fa-save"></i>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="form-container">
        <div class="row">
          <div class="col col-3">
            <label for>Name</label>
            <input type="text" placeholder="Testando" />
          </div>
          <div class="col col-3">
            <label for>Nacionalidade</label>
            <input type="text" name id />
          </div>
          <div class="col col-3">
            <label for>Provincia</label>
            <input type="text" name id />
          </div>
        </div>
        <div class="row">
          <div class="col col-3">
            <label for>Name</label>
            <input type="text" name id />
          </div>
          <div class="col col-3">
            <label for>Nacionalidade</label>
            <input type="text" name id />
          </div>
        </div>
        <div class="row">
          <div class="col col-3">
            <label for>Name</label>
            <input type="text" name id />
          </div>
          <div class="col col-3">
            <label for>Nacionalidade</label>
            <input type="text" name id />
          </div>
          <div class="col col-3">
            <label for>Provincia</label>
            <input type="text" name id />
          </div>
        </div>
        <div class="row">
          <div class="col col-2 change-size">
            <div class="col col-3 change-size">
              <input-radio
                name="example-1"
                label="I love citrus"
                :value="true"
                v-model="exampleBool"
              ></input-radio>
            </div>
            <div class="col col-3 change-size">
              <input-radio
                name="example-1"
                label="I mildy like citrus"
                :value="false"
                v-model="exampleBool"
              ></input-radio>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-3">
            <label for>Name</label>
            <input type="text" name id />
          </div>
          <div class="col col-3">
            <label for>Nacionalidade</label>
            <input type="text" name id />
          </div>
          <div class="col col-3">
            <label for>Imagem de Capa</label>
            <upload-photo v-model="form.file" :OnChange="SelectImage" />
          </div>
        </div>
        <div class="row">
          <div class="col col-3">
            <label for>Name</label>
            <input type="text" name id />
          </div>
          <div class="col col-3">
            <label for>Nacionalidade</label>
            <input type="text" name id />
          </div>
          <div class="col col-3">
            <label for>Provincia</label>
            <input type="text" name id />
          </div>
        </div>
        <div class="row">
          <div class="col change-size">
            <div class="col col-12 change-size">
              <label for>VueJs</label>
              <input type="checkbox" name="resident" id />
            </div>
            <div class="col col-12 change-size">
              <label for>React</label>
              <input type="checkbox" name="resident" id />
            </div>
            <div class="col col-10 change-size">
              <label for>Angular 2+</label>
              <input type="checkbox" name="resident" id />
            </div>
            <div class="col col-12 change-size">
              <label for>JQuery</label>
              <input type="checkbox" name="resident" id />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <alert text="Sucesso" message="Anyone with access can view your invited visitors." type="toast--green"></alert>
    </div>
  </div>
</template>
<script>
import InputRadio from '@/components/BackOffice/Layouts/Radio-Input.vue'
import UploadPhoto from '@/components/BackOffice/Layouts/Upload-Photo.vue'
import Alert from '@/components/BackOffice/Layouts/Alert.vue'

export default {
  components: {
    'input-radio': InputRadio,
    'upload-photo': UploadPhoto,
    'alert': Alert
  },
  data () {
    return {
      exampleBool: '',
      form: {}
    }
  },
  methods: {
    SelectImage (file) {
      this.form.file = file
    }
  }
}
</script>
<style style="scss" scoped></style>
