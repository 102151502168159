export default {
  en: {
    login: {
      btnLogin: 'Login',
      recoveryPassword: 'Recover Password',
      wrongCredentials: 'Invalid username or password'
    },
    recoveryPassword: {
      recovery: 'Recover',
      cancel: 'Cancel',
      back: 'Back',
      alert: {
        success: 'Sent',
        error: 'Error',
        changed: 'Password Changed'
      }
    },
    header: {
      edit: 'Settings',
      change: 'Change Password'
    },
    navigationDrawer: {
      menu: {
        subMenu1: 'Dashboard',
        subMenu2: 'Subscriptions',
        subMenu3: 'Operators',
        subMenu4: 'Transactions',
        subMenu5: {
          title: 'ADVANCED',
          token: 'Tokens'
        },
        subMenu6: 'Logout'
      }
    },
    table: {
      itens: 'Itens'
    },
    alert: {
      success: 'Sucess',
      error: 'Error'
    },
    editProfile: {
      btnSave: 'Save',
      name: 'Name',
      status: 'Status',
      statusActive: 'Actived',
      statusDisable: 'Disabled',
      nif: 'NIF',
      alert: {
        success: 'Profile update with success',
        error: 'Could not update profile',
        getError: 'Unable to load the information.'
      },
      route: {
        title: 'Settings'
      }
    },
    changePassword: {
      btnAlter: 'Change',
      currentLabel: 'Current Password',
      newLabel: 'New Password',
      confirmLabel: 'Confirm New Password',
      alert: {
        success: 'Passowrd saved with success',
        error: 'Could not change password'
      },
      route: {
        title: 'Change Password'
      }
    },
    dashboard: {
      cards: {
        subscriptions: 'Subscriptions',
        operators: 'Operators',
        payments: 'Payments',
        refunds: 'Refunds'
      },
      charts: {
        value: 'Value in AOA',
        payments: 'Payments',
        refunds: 'Refunds',
        of: '',
        title: 'Transactions'
      },
      lastTransactions: {
        title: 'Last Transactions',
        more: 'More'
      },
      weeksCounts: {
        first: 'qwerty'
      },
      modal: {
        type: 'Type',
        customer: 'Customer',
        provider: 'Provider',
        operatorName: 'Operator Name'
      },
      route: {
        title: 'Dashboard'
      }
    },
    subscriptions: {
      get: {
        bankName: 'Bank Name',
        merchantName: 'Merchant Name',
        provider: 'Provider'
      },
      newSubscription: {
        addBtn: 'New Subscription',
        modal: {
          title: 'Subscription',
          subscribeBtn: 'Subscribe',
          typePlaceholder: 'Select Type',
          bankNamePlaceholder: 'Select Bank'
        }
      },
      table: {
        labels: {
          bankName: 'Bank',
          state: 'Status',
          merchantName: 'Merchant',
          type: 'Subscription'
        }
      },
      alert: {
        getAllError: 'Couldnt load subscriptions. Refresh the page.',
        getError: 'Unable to load the information.',
        getBanksError: 'Could not load bank information. Refresh the page.',
        error: 'Unable to subscribe',
        merchantNotFound: 'Merchant not found',
        merchantNotValidForAccount: 'Merchant Not Valid For this Account',
        banckNotFound: 'Bank not found',
        merchantNotActive: 'Merchant not active',
        subscriptionAlreadyExist: 'Subscription already exist',
        success: 'Subscription registered successfully'
      },
      route: {
        listTitle: 'Subscriptions',
        registTitle: 'Regist Subscription',
        editTitle: 'Edit Subscription'
      }
    },
    operators: {
      table: {
        labels: {
          username: 'Username',
          bankName: 'Bank',
          state: 'Status'
        },
        refundCodes: {
          btnGenerated: 'Generate Refund Code',
          labels: {
            code: 'Code',
            enabled: 'Status',
            cratedDate: 'Creation Date',
            expirationDate: 'Expiration Date'
          },
          status: {
            active: 'Enabled',
            disable: 'Disabled'
          }
        }
      },
      modalGenerate: {
        initialGenerate: 'Generate Refund Code',
        doneGenerate: 'refund Code Generated',
        generate: 'Generate'
      },
      modalDelete: {
        question: 'Do you want to remove the Refund Code?',
        btnRemove: 'Delete',
        cancel: 'Cancel'
      },
      alert: {
        getAllError: 'Refund Codes could not be loaded. Refresh the page.',
        success: 'Refund Code generated successfully.',
        remove: 'Refund Code removed'
      },
      route: {
        listTitle: 'Operators',
        registTitle: 'Regist Operator',
        editTitle: 'Details Operator'
      }
    },
    transactions: {
      exportBtn: 'Export',
      table: {
        labels: {
          customer: 'Customer',
          amount: 'Amount',
          provider: 'Provider',
          type: 'Operation',
          status: 'Status',
          source: 'Source'
        }
      },
      modal: {
        type: 'Type',
        customer: 'Customer',
        provider: 'Provider',
        operatorName: 'Operator Name',
        creationDate: 'Creation Date'
      },
      filter: {
        title: 'Filter Transactions',
        transactionsStatus: 'Status',
        statusPlaceholder: 'Select Status',
        operationType: 'Operation Type',
        telephone: 'Phone Number',
        minValue: 'Min. Value',
        maxValue: 'Max. Value',
        from: 'From Date',
        to: 'To Date',
        filterBtn: 'Filter',
        source: 'Source'
      },
      type: {
        payment: 'Payment',
        refund: 'Refund',
        authorization: 'Authorization',
        cancelation: 'Cancelation'
      },
      multiselect: {
        transactionsType: {
          accepted: 'Accepted',
          rejected: 'Rejected',
          new: 'New'
        },
        operationType: {
          refund: 'Refund',
          payment: 'Payment'
        },
        source: {
          api: 'Api',
          app: 'Mobile App'
        }
      },
      source: {
        api: 'Api',
        app: 'Mobile App'
      },
      route: {
        listTitle: 'Transactions',
        registTitle: 'Regist Transaction',
        editTitle: 'Edit Transaction'
      }
    },
    tokens: {
      newBtn: 'New Token',
      modal: {
        question: 'Do you want to remove the token?',
        removeBtn: 'Remove',
        cancelBtn: 'Cancel'
      },
      table: {
        labels: {
          label: 'Label',
          environment: 'Environment',
          state: 'Status',
          createdDate: 'Creation Date:',
          expiry: 'Expiration Date:'
        }
      },
      token: {
        titleInitialStatus: 'Generate Token',
        titleSuccessStatus: 'Token Generated',
        description: 'Token Description',
        dateExpiryPlaceholder: 'Expiration Date: 2021-01-12',
        tokenTitle: 'TOKEN',
        note: 'NOTE:',
        noteText: 'Copy and save this TOKEN, because it only appears once.',
        generateBtn: 'Generate'
      },
      alert: {
        success: 'Token successfully generated.',
        getError: 'Couldnt load Token. Refresh the page.',
        removeSuccess: 'Token removed',
        error: 'This operation could not be performed',
        merchantDoesntMatch: 'Merchant id does not match authenticated account',
        merchantWithoutActive: 'Merchant without active subscription',
        tokenIsInRequested: 'Token is in requested state',
        tokenNotFound: 'Token not found',
        merchantNotSync: 'Merchant not synchronized with EMIS'
      },
      route: {
        listTitle: 'Tokens'
      }
    },
    filters: {
      requestStatus: {
        requested: 'Requested',
        active: 'Active',
        suspended: 'Suspended',
        processing: 'Processing',
        creationFailed: 'Creation Failed',
        sandbox: 'Sandbox'
      },
      transactionsStatus: {
        new: 'Processing',
        initial: 'Initial',
        accepted: 'Accepted',
        rejected: 'Rejected'
      }
    },
    months: {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December'
    },
    weeksCounts: {
      first: '1st Week',
      second: '2nd Week',
      third: '3rd Week',
      fourth: '4th Week'
    },
    mobileWeeks: {
      first: '1st',
      second: '2nd',
      third: '3rd',
      fourth: '4th'
    }
  }
}
