<template>
  <div>
    <div class="main-header">
      <div class="main-header__heading">
        <bread-crumbs></bread-crumbs>
      </div>
      <div class="col col-9 head-info">
        <button class="btn btn-success" @click="processForm" :disabled="isRequesting">
          <span v-if="!isRequesting">{{$t('changePassword.btnAlter')}}</span>
          <div class="loading-dots" v-if="isRequesting">
            <div class="loading-dots--dot"></div>
            <div class="loading-dots--dot"></div>
            <div class="loading-dots--dot"></div>
          </div>
        </button>
        <div class="float-btn" @click="processForm">
          <i v-if="!isRequesting" class="fas fa-save"></i>
          <div v-if="isRequesting" class="loader-5 center"><span></span></div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="form-container">
        <div class="row">
          <div class="col col-3">
            <label for>{{$t('changePassword.currentLabel')}}</label>
            <input
            type="password"
            v-model="form.old"
            name="old"
            placeholder="*********"
            class="form-control regist"
            :class="{'is-input-danger': errors.has('old')}"
            id="old"
            v-validate="'required'"
            data-vv-as="Senha Atual"
            />
            <span
                v-show="errors.has('old')"
                class="help is-danger"
              >{{ errors.first('old') }}</span>
          </div>
          <div class="col col-3">
            <label for>{{$t('changePassword.newLabel')}}</label>
            <input
            type="password"
            v-model="form.new"
            ref="new"
            name="new"
            placeholder="*********"
            :class="{'is-input-danger': errors.has('new')}"
            id="new"
            v-validate="'required'"
            data-vv-as="Nova Senha"
            />
            <span
                v-show="errors.has('new')"
                class="help is-danger"
              >{{ errors.first('new') }}</span>
          </div>
          <div class="col col-3">
            <label for>{{$t('changePassword.confirmLabel')}}</label>
            <input
            type="password"
            v-model="form.confirm_new"
            name="confirm_new"
            placeholder="*********"
            :class="{'is-input-danger': errors.has('confirm_new')}"
            id="confirm_new"
            v-validate="'required|confirmed:new|min:6'"
            data-vv-as="Confirmar Senha"
            />
            <span
                v-show="errors.has('confirm_new')"
                class="help is-danger"
              >{{ errors.first('confirm_new') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <alert text="Sucesso" v-if="hadSuccess" :message="hadSuccess" type="toast--green"></alert>
      <alert text="Erro" v-if="hadError" :message="hadError" type="toast--red"></alert>
    </div>
  </div>
</template>
<script>
import Alert from '@/components/BackOffice/Layouts/Alert.vue'

export default {
  components: {
    'alert': Alert
  },
  data () {
    return {
      form: {},
      updatedForm: {},
      hadSuccess: '',
      hadError: '',
      isRequesting: false
    }
  },
  methods: {
    /*
    *  ProcessForm: This method will validate the form using vee-validate
    *  component and then call the action method defined for this view
    *  if everything passes the validation.
    */
    async processForm () {
      this.hadError = ''
      const result = await this.$validator.validateAll()
      return result ? this.AlterPassword() : result
    },
    /**
     *  SubscribeBanks: This method will request all the providers available
     *  and store the response in the state property: providers.
     */
    async AlterPassword () {
      this.isRequesting = true
      this.updatedForm.new = this.form.new
      this.updatedForm.old = this.form.old
      try {
        await this.axios.put(`/merchants/accounts/me/passwords`, this.updatedForm)
        this.hadSuccess = this.$t('changePassword.alert.success')
        this.clearForm()
      } catch (e) {
        this.hadError = this.$t('changePassword.alert.error')
        this.clearForm()
      }
      this.isRequesting = false
      setTimeout(() => {
        this.hadSuccess = ''
        this.hadError = ''
      }, 8000)
    },
    clearForm () {
      this.form = {}
    }
  },
  created () {
    this.userName = localStorage.getItem('vpos_body_name') ? localStorage.getItem('vpos_body_name') : 'Kevin Durant'
  }
}
</script>
<style style="scss" scoped></style>
