var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"form-container"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-2 change-size"},[_c('div',{staticClass:"col col-3 change-size"},[_c('input-radio',{attrs:{"name":"example-1","label":"I love citrus","value":true},model:{value:(_vm.exampleBool),callback:function ($$v) {_vm.exampleBool=$$v},expression:"exampleBool"}})],1),_c('div',{staticClass:"col col-3 change-size"},[_c('input-radio',{attrs:{"name":"example-1","label":"I mildy like citrus","value":false},model:{value:(_vm.exampleBool),callback:function ($$v) {_vm.exampleBool=$$v},expression:"exampleBool"}})],1)])]),_c('div',{staticClass:"row"},[_vm._m(4),_vm._m(5),_c('div',{staticClass:"col col-3"},[_c('label',{attrs:{"for":""}},[_vm._v("Imagem de Capa")]),_c('upload-photo',{attrs:{"OnChange":_vm.SelectImage},model:{value:(_vm.form.file),callback:function ($$v) {_vm.$set(_vm.form, "file", $$v)},expression:"form.file"}})],1)]),_vm._m(6),_vm._m(7)])]),_c('div',[_c('alert',{attrs:{"text":"Sucesso","message":"Anyone with access can view your invited visitors.","type":"toast--green"}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-header"},[_c('div',{staticClass:"main-header__heading"},[_vm._v("Estorno")]),_c('div',{staticClass:"col col-9"},[_c('button',{staticClass:"btn btn-success"},[_vm._v("Salvar")]),_c('div',{staticClass:"float-btn"},[_c('i',{staticClass:"fas fa-save"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-3"},[_c('label',{attrs:{"for":""}},[_vm._v("Name")]),_c('input',{attrs:{"type":"text","placeholder":"Testando"}})]),_c('div',{staticClass:"col col-3"},[_c('label',{attrs:{"for":""}},[_vm._v("Nacionalidade")]),_c('input',{attrs:{"type":"text","name":"","id":""}})]),_c('div',{staticClass:"col col-3"},[_c('label',{attrs:{"for":""}},[_vm._v("Provincia")]),_c('input',{attrs:{"type":"text","name":"","id":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-3"},[_c('label',{attrs:{"for":""}},[_vm._v("Name")]),_c('input',{attrs:{"type":"text","name":"","id":""}})]),_c('div',{staticClass:"col col-3"},[_c('label',{attrs:{"for":""}},[_vm._v("Nacionalidade")]),_c('input',{attrs:{"type":"text","name":"","id":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-3"},[_c('label',{attrs:{"for":""}},[_vm._v("Name")]),_c('input',{attrs:{"type":"text","name":"","id":""}})]),_c('div',{staticClass:"col col-3"},[_c('label',{attrs:{"for":""}},[_vm._v("Nacionalidade")]),_c('input',{attrs:{"type":"text","name":"","id":""}})]),_c('div',{staticClass:"col col-3"},[_c('label',{attrs:{"for":""}},[_vm._v("Provincia")]),_c('input',{attrs:{"type":"text","name":"","id":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col col-3"},[_c('label',{attrs:{"for":""}},[_vm._v("Name")]),_c('input',{attrs:{"type":"text","name":"","id":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col col-3"},[_c('label',{attrs:{"for":""}},[_vm._v("Nacionalidade")]),_c('input',{attrs:{"type":"text","name":"","id":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-3"},[_c('label',{attrs:{"for":""}},[_vm._v("Name")]),_c('input',{attrs:{"type":"text","name":"","id":""}})]),_c('div',{staticClass:"col col-3"},[_c('label',{attrs:{"for":""}},[_vm._v("Nacionalidade")]),_c('input',{attrs:{"type":"text","name":"","id":""}})]),_c('div',{staticClass:"col col-3"},[_c('label',{attrs:{"for":""}},[_vm._v("Provincia")]),_c('input',{attrs:{"type":"text","name":"","id":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col change-size"},[_c('div',{staticClass:"col col-12 change-size"},[_c('label',{attrs:{"for":""}},[_vm._v("VueJs")]),_c('input',{attrs:{"type":"checkbox","name":"resident","id":""}})]),_c('div',{staticClass:"col col-12 change-size"},[_c('label',{attrs:{"for":""}},[_vm._v("React")]),_c('input',{attrs:{"type":"checkbox","name":"resident","id":""}})]),_c('div',{staticClass:"col col-10 change-size"},[_c('label',{attrs:{"for":""}},[_vm._v("Angular 2+")]),_c('input',{attrs:{"type":"checkbox","name":"resident","id":""}})]),_c('div',{staticClass:"col col-12 change-size"},[_c('label',{attrs:{"for":""}},[_vm._v("JQuery")]),_c('input',{attrs:{"type":"checkbox","name":"resident","id":""}})])])])
}]

export { render, staticRenderFns }