import Home from '@/views/FrontOffice/Home'
import View from '@/views/FrontOffice/View'

// Auth
import ContactUs from '@/views/FrontOffice/ContactUs'
import Products from '@/views/FrontOffice/Products'
import Dashboard from '@/views/FrontOffice/Dashboard'
import FrontResetPassword from '@/views/BackOffice/Auth/AdminResetPassword'

// Auth routes
const Homepage = [{
  path: '/front',
  component: View,
  meta: {
    title: 'Home'
  },
  children: [{
    path: '/',
    name: 'FrontDashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard'
    }
  },
  {
    path: 'format/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Exemplo'
    }
  },
  {
    path: 'contact-us',
    name: 'ContactUs',
    component: ContactUs,
    meta: {
      title: 'Contacte-nos'
    }
  },
  {
    path: 'products',
    name: 'Products',
    component: Products,
    meta: {
      title: 'Produtos'
    }
  },
  {
    path: 'reset-password',
    name: 'FrontResetPassword',
    component: FrontResetPassword,
    meta: {
      title: 'Redefinir Password'
    }
  }
  ]
}
]

// Home page
export default [...Homepage]
