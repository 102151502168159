<template>
  <div>
    <div class="main-header">
      <div class="main-header__heading">
        <bread-crumbs></bread-crumbs>
      </div>
      <div class="col col-9 head-info">
        <!-- <button class="btn btn-success" @click="processForm" :disabled="isRequesting">
          <span v-if="!isRequesting">{{$t('editProfile.btnSave')}}</span>
          <div class="loading-dots" v-if="isRequesting">
            <div class="loading-dots--dot"></div>
            <div class="loading-dots--dot"></div>
            <div class="loading-dots--dot"></div>
          </div>
        </button>
        <div class="float-btn" @click="processForm">
          <i v-if="!isRequesting" class="fas fa-save"></i>
          <div v-if="isRequesting" class="loader-5 center"><span></span></div>
        </div> -->
      </div>
    </div>
    <div class="container">
      <div class="form-container">
        <div class="row">
          <div class="col col-4">
            <label for>{{$t('editProfile.name')}}</label>
            <input
            type="text"
            v-model="form.name"
            name="name"
            placeholder="Joao Pessoa"
            class="form-control regist"
            :class="{'is-input-danger': errors.has('name')}"
            id="name"
            v-validate="'required'"
            data-vv-as="Nome"
            disabled
            />
            <span
                v-show="errors.has('name')"
                class="help is-danger"
              >{{ errors.first('name') }}</span>
          </div>
          <div class="col col-4">
            <label for>{{$t('editProfile.status')}}</label>
            <input
            type="text"
            v-model="form.status"
            name="status"
            placeholder="Actives"
            :class="{'is-input-danger': errors.has('status')}"
            id="status"
            v-validate="'required|min:6'"
            data-vv-as="status"
            disabled
            />
            <span
                v-show="errors.has('status')"
                class="help is-danger"
              >{{ errors.first('status') }}</span>
          </div>
          <div class="col col-4">
            <label for>{{$t('editProfile.nif')}}</label>
            <input
            type="text"
            v-model="form.nif"
            name="nif"
            placeholder="005123456789LA047"
            :class="{'is-input-danger': errors.has('nif')}"
            id="nif"
            maxlength="14"
            minlength="10"
            v-validate="'required|min:10|max:14'"
            data-vv-as="NIF"
            disabled
            />
            <span
                v-show="errors.has('nif')"
                class="help is-danger"
              >{{ errors.first('nif') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <alert :text="$t('alert.success')" v-if="hadSuccess" :message="hadSuccess" type="toast--green"></alert>
      <alert :text="$t('alert.error')" v-if="hadError" :message="hadError" type="toast--red"></alert>
    </div>
  </div>
</template>
<script>
import Alert from '@/components/BackOffice/Layouts/Alert.vue'

export default {
  components: {
    'alert': Alert
  },
  data () {
    return {
      form: {},
      datas: {},
      updatedForm: {},
      hadSuccess: '',
      hadError: '',
      phoneNumber: '',
      isRequesting: false,
      bindProps: {
        mode: 'national',
        defaultCountry: 'AO',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        dynamicPlaceholder: false,
        placeholder: 'Insira o telefone',
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        preferredCountries: [],
        onlyCountries: ['AO'],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 9,
        wrapperClasses: '',
        inputClasses: '',
        dropdownOptions: {
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: false
        }
      }
    }
  },
  methods: {
    /*
    *  ProcessForm: This method will validate the form using vee-validate
    *  component and then call the action method defined for this view
    *  if everything passes the validation.
    */
    async processForm () {
      this.hadError = ''
      const result = await this.$validator.validateAll()
      return result ? this.EditProfile() : result
    },
    /**
     * GetUser: This method will fire a GET request and then
     * assign the response data into the state property: form
     */
    async GetUser () {
      this.isRequesting = true
      var token = localStorage.getItem('user_token')
      try {
        const result = await this.axios.get(`/merchants/sessions/${token}`)
        this.datas = result.data
        this.form.name = this.datas.merchant.name
        this.form.status = this.$t(this.$options.filters.merchantStatus(this.datas.merchant.state))
        this.form.nif = this.datas.merchant.nif
      } catch (e) {
        this.hadError = this.$t('editProfile.alert.getError')
      }
      this.isRequesting = false
    },
    /**
     *  EditProfile: This method will request all the providers available
     *  and store the response in the state property: providers.
     */
    async EditProfile () {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      this.form.phone_number = `+244${this.myTrim(this.phoneNumber)}`
      try {
        await this.axios.patch(`/merchants/${merchantID}s`, this.form)
        this.hadSuccess = this.$t('editProfile.alert.success')
        this.GetSubscriptions()
      } catch (e) {
        this.hadError = this.$t('editProfile.alert.error')
      }
      this.isRequesting = false
      setTimeout(() => {
        this.hadSuccess = ''
        this.hadError = ''
      }, 4000)
    },
    myTrim (x) {
      return x.replace(/[^\d]/g, '')
    }
  },
  created () {
    this.GetUser()
    this.userName = localStorage.getItem('vpos_body_name') ? localStorage.getItem('vpos_body_name') : 'Kevin Durant'
  }
}
</script>
<style style="scss" scoped></style>
