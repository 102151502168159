<template>
  <div class="control-group">
    <label class="control control--radio">
      {{label}}
      <input
        v-bind="$attrs"
        type="radio"
        :name="name"
        :value="value"
        :checked="isSelected"
        :disabled="disabled"
        @change="$emit('input', value)"
      />
      <div class="control__indicator"></div>
    </label>
  </div>
</template>
<script>
export default {
  name: 'input-radio',
  model: {
    prop: 'model'
  },
  props: {
    model: {
      type: [String, Number, Boolean, Object],
      required: true
    },
    label: { type: String, default: 'default', required: true },
    name: String,
    value: { type: [String, Number, Boolean, Object], required: true },
    disabled: Boolean
  },
  data () {
    return {
      focused: false,
      inheritAttrs: false
    }
  },
  computed: {
    classesObject () {
      return {
        checked: this.isSelected,
        disabled: this.disabled,
        focused: this.focused
      }
    },
    isValueObject () {
      return this.value !== null && typeof this.value === 'object'
    },
    isSelected () {
      if (this.isValueObject) {
        return this.isObjectEqual(this.model, this.value)
      }
      return this.model === this.value
    }
  },
  methods: {
    isObjectEqual (a, b) {
      return JSON.stringify(a) === JSON.stringify(b)
    }
  }
}
</script>
<style lang="scss" scoped>
.control-group {
  margin-right: 16px;
}
.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0;
  cursor: pointer;
  text-align: left;
  font-family: "Open Sans Regular";
  font-size: 12px;
  letter-spacing: 0;
  color: #707070;
  opacity: 1;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 15px;
  width: 15px;
  background: #fff;
  border: solid 1px;
  border-color: #d05d1c;
}
.control--radio .control__indicator {
  border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #ccc;
}
.control input:checked ~ .control__indicator {
//   background: #d05d1c;
  background-color: #fff;
  border-color: #d05d1c;
  display: flex;
    justify-content: center;
    align-items: center;
}
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
//   background: #d05d1c;
    background-color: #fff;
  border-color: #d05d1c;
}
.control input:disabled ~ .control__indicator {
  background-color: #fff;
  border-color: #d05d1c;
  opacity: 0.6;
  pointer-events: none;
}
.control__indicator:after {
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control__indicator:after {
  display: block;
}
.control--checkbox .control__indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control--checkbox input:disabled ~ .control__indicator:after {
//   border-color: #7b7b7b;
  background-color: #fff;
  border-color: #d05d1c;
}
.control--radio .control__indicator:after {
//   left: 4.8px;
//   top: 5px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #d05d1c;
}
.control--radio input:disabled ~ .control__indicator:after {
//   background: #7b7b7b;
  background-color: #fff;
  border-color: #d05d1c;
}
</style>
