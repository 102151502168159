<template>
  <div>
    <div class="main-header">
      <div class="main-header__heading">Dashboard</div>
    </div>
    <div class="main-overview">
      <div class="overviewcard">
        <div class="overviewcard__status overviewcard__status__sucess"></div>
        <div class="overviewcard__describe">
          <div class="overviewcard__icon">GPO</div>
          <div class="overviewcard__info">30</div>
        </div>
      </div>
      <div class="overviewcard">
        <div class="overviewcard__status overviewcard__status__danger"></div>
        <div class="overviewcard__describe">
          <div class="overviewcard__icon">Vendas</div>
          <div class="overviewcard__info">300</div>
        </div>
      </div>
      <div class="overviewcard">
        <div class="overviewcard__status overviewcard__status__primary"></div>
        <div class="overviewcard__describe">
          <div class="overviewcard__icon">Pendentes</div>
          <div class="overviewcard__info">234</div>
        </div>
      </div>
      <div class="overviewcard">
        <div class="overviewcard__status overviewcard__status__warning"></div>
        <div class="overviewcard__describe">
          <div class="overviewcard__icon">Facturas</div>
          <div class="overviewcard__info">1500</div>
        </div>
      </div>
    </div>
    <div class="main-cards">
      <div class="dashboard-card">
        <div><v-chart v-bind:chartData="chartLineData"></v-chart></div>
      </div>
      <div class="dashboard-card">
        <div><v-chart v-bind:chartData="chartData"></v-chart></div>
      </div>
      <div class="dashboard-card">
      </div>
    </div>
  </div>
</template>
<script>
const grades =
  [{ Name: 'Mary', s1Grade: 98, s2Grade: 95 },
    { Name: 'Sam', s1Grade: 76, s2Grade: 81 },
    { Name: 'Steve', s1Grade: 85, s2Grade: 84 },
    { Name: 'Jill', s1Grade: 87, s2Grade: 90 },
    { Name: 'Ben', s1Grade: 63, s2Grade: 75 },
    { Name: 'Brian', s1Grade: 65, s2Grade: 62 },
    { Name: 'Isabel', s1Grade: 90, s2Grade: 85 }]

export default {
  components: {},
  data () {
    return {
      chartData: {
        chartType: 'barChart',
        selector: 'chart',
        title: 'Student Grades',
        subtitle: '2018 - 2019',
        width: 400,
        height: 400,
        dim: 'Name',
        metric: ['s1Grade', 's2Grade'],
        data: grades,
        legends: {
          enabled: true
        },
        overrides: {
          palette: {
            fill: ['#D05D1C', '#11559C'],
            stroke: '#0000007E'
          },
          x: {
            ticks: 10
          },
          y: {
            axisWidth: 40
          }
        }
      },
      chartLineData: {
        chartType: 'lineGraph',
        selector: 'graph',
        title: 'Student Grades',
        subtitle: '2018 - 2019',
        width: 400,
        height: 400,
        dim: 'Name',
        metric: ['s1Grade', 's2Grade'],
        data: grades,
        legends: {
          enabled: true
        },
        overrides: {
          palette: {
            fill: ['#D05D1C', '#11559C'],
            stroke: '#0000007E'
          },
          x: {
            ticks: 10
          },
          y: {
            axisWidth: 40
          }
        }
      }
    }
  },
  methods: {}
}
</script>
<style style="scss" scoped>
.main-overview {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(265px, 1fr)
  ); /* Where the magic happens */
  grid-auto-rows: 94px;
  grid-gap: 20px;
  margin: 20px;
}

.overviewcard {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);
}

.overviewcard__icon {
  display: flex;
  text-align: center;
}

.overviewcard__describe {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-grow: 1;
}

.overviewcard__status {
  content: "";
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.overviewcard__status__sucess {
  background-color: #2bde3f;
}

.overviewcard__status__danger {
  background-color: #ff5733;
}

.overviewcard__status__primary {
  background-color: #1d72f3;
}

.overviewcard__status__warning {
  background-color: #ffc007;
}

.overviewcard__icon {
  font-family: "Open Sans Regular";
  font-size: 14;
  color: slategray;
}

.overviewcard__info {
  font-family: "Open Sans SemiBold";
  font-size: 26px;
  color: slategray;
}
</style>
