export default {
  pt: {
    login: {
      btnLogin: 'Entrar',
      recoveryPassword: 'Recuperar Senha',
      wrongCredentials: 'Nome de usuário ou senha inválidos'
    },
    recoveryPassword: {
      recovery: 'Recuperar',
      cancel: 'Cancelar',
      back: 'Voltar',
      alert: {
        success: 'Enviado',
        error: 'Erro',
        changed: 'Senha Alterada'
      }
    },
    header: {
      edit: 'Configurações',
      change: 'Alterar Senha'
    },
    navigationDrawer: {
      menu: {
        subMenu1: 'Visão geral',
        subMenu2: 'Subscrições',
        subMenu3: 'Operadores',
        subMenu4: 'Transacções',
        subMenu5: {
          title: 'AVANÇADOS',
          token: 'Chaves'
        },
        subMenu6: 'Sair'
      }
    },
    table: {
      itens: 'Itens'
    },
    alert: {
      success: 'Sucesso',
      error: 'Erro'
    },
    editProfile: {
      btnSave: 'Salvar',
      name: 'Nome',
      status: 'Estado',
      statusActive: 'Activo',
      statusDisable: 'Desativado',
      nif: 'NIF',
      alert: {
        success: 'Perfil alterado com Sucesso',
        error: 'Não foi possível alterar o perfil',
        getError: 'Não foi possível carregar as informações.'
      },
      route: {
        title: 'Configurações'
      }
    },
    changePassword: {
      btnAlter: 'Alterar',
      currentLabel: 'Senha Atual',
      newLabel: 'Nova Senha',
      confirmLabel: 'Confirmar Nova Senha',
      alert: {
        success: 'Senha alterada com Sucesso',
        error: 'Não foi possível alterar a senha'
      },
      route: {
        title: 'Alterar Password'
      }
    },
    dashboard: {
      cards: {
        subscriptions: 'Subscrições',
        operators: 'Operadores',
        payments: 'Recebimentos',
        refunds: 'Estorno'
      },
      charts: {
        value: 'Valor em AOA',
        payments: 'Recebimento',
        refunds: 'Estorno',
        of: 'de',
        title: 'Transacções'
      },
      lastTransactions: {
        title: 'Ultimas Transacções',
        more: ' Ver mais '
      },
      weeksCounts: {
        first: 'ytrewq'
      },
      modal: {
        type: 'Tipo',
        customer: 'Cliente',
        provider: 'Provedor',
        operatorName: 'Nome do Operador'
      },
      route: {
        title: 'Visão Geral'
      }
    },
    subscriptions: {
      get: {
        bankName: 'Banco',
        merchantName: 'Comerciante',
        provider: 'Provedor'
      },
      modal: {
        newSubscription: {
          type: 'Serviço',
          supportBank: 'Banco de Apoio',
          iban: 'IBAN'
        }
      },
      newSubscription: {
        addBtn: 'Nova Subscrição',
        modal: {
          title: 'Subscrições',
          subscribeBtn: 'Subscrever',
          typePlaceholder: 'Selecione o Tipo',
          bankNamePlaceholder: 'Selecione o Banco'
        }
      },
      table: {
        labels: {
          bankName: 'Banco',
          state: 'Estado',
          merchantName: 'Comerciante',
          type: 'Subscrição'
        }
      },
      alert: {
        getAllError: 'Não foi possível carregar as subscrições. Actualize a página.',
        getError: 'Não foi possível carregar as informações.',
        getBanksError: 'Não foi possível carregar as informações dos bancos. Actualize a página.',
        error: 'Não foi possível efetuar a subscrição',
        merchantNotFound: 'Comerciante não encontrado',
        merchantNotValidForAccount: 'Comerciante não válido para esta conta',
        banckNotFound: 'Banco não encontrado',
        merchantNotActive: 'Comerciante não activo',
        subscriptionAlreadyExist: 'Subscrição já foi feita',
        success: 'Subscrição feita com sucesso'
      },
      route: {
        listTitle: 'Subscrições',
        registTitle: 'Registar Subscrição',
        editTitle: 'Editar Subscrição'
      }
    },
    operators: {
      table: {
        labels: {
          username: 'Nome de Usuário',
          bankName: 'Banco',
          state: 'Estado'
        },
        refundCodes: {
          btnGenerated: 'Gerar Codigo Estorno',
          labels: {
            code: 'Código',
            enabled: 'Estado',
            cratedDate: 'Data de Criação',
            expirationDate: 'Data de Expiração'
          },
          status: {
            active: 'Activado',
            disable: 'Desactivado'
          }
        }
      },
      modalGenerate: {
        initialGenerate: 'Gerar Código de Estorno',
        doneGenerate: 'Código de Estorno Gerado',
        generate: 'Gerar'
      },
      modalDelete: {
        question: 'Pretende remover o Código de Estorno?',
        btnRemove: 'Remover',
        cancel: 'Cancelar'
      },
      alert: {
        getAllError: 'Não foi possível carregar os Códigos de Estorno. Actualize a página.',
        success: 'Código de estorno gerado com sucesso.',
        remove: 'Código de estorno Removido'
      },
      route: {
        listTitle: 'Operadores',
        registTitle: 'Registar Operador',
        editTitle: 'Detalhes Operador'
      }
    },
    transactions: {
      exportBtn: 'Exportar',
      table: {
        labels: {
          customer: 'Clientes',
          amount: 'Valor',
          provider: 'Provedor',
          type: 'Operação',
          status: 'Estado',
          source: 'Origem'
        }
      },
      modal: {
        type: 'Tipo',
        customer: 'Cliente',
        provider: 'Provedor',
        operatorName: 'Nome do Operador',
        creationDate: 'Data de Criação'
      },
      filter: {
        title: 'Filtrar Transacções',
        transactionsStatus: 'Estado da Transacção',
        statusPlaceholder: 'Tipo da Operação',
        operationType: 'Tipo da Operação',
        telephone: 'Telemovél',
        minValue: 'Valor (Mínimo)',
        maxValue: 'Valor (Máximo)',
        from: 'Período de Compesação (Apartir De)',
        to: 'Período de Compesação (Até)',
        filterBtn: 'Filtrar',
        source: 'Origem'
      },
      type: {
        payment: 'Pagamento',
        refund: 'Estorno',
        authorization: 'Autorização',
        cancelation: 'Cancelamento'
      },
      multiselect: {
        transactionsType: {
          accepted: 'Aceite',
          rejected: 'Rejeitado',
          new: 'Em Processamento'
        },
        operationType: {
          refund: 'Estorno',
          payment: 'Recebimento'
        },
        source: {
          api: 'Api',
          app: 'Aplicativo Móvel'
        }
      },
      source: {
        api: 'Api',
        app: 'Aplicativo Móvel'
      },
      route: {
        listTitle: 'Transacções',
        registTitle: 'Registrar Transacção',
        editTitle: 'Editar Transacção'
      }
    },
    tokens: {
      newBtn: 'Nova Chave',
      modal: {
        question: 'Pretende remover o token?',
        removeBtn: 'Remover',
        cancelBtn: 'Cancelar'
      },
      table: {
        labels: {
          label: 'Etiqueta',
          environment: 'Ambiente',
          state: 'Estado',
          createdDate: 'Data de Criação',
          expiry: 'Data de Expiração'
        }
      },
      token: {
        titleInitialStatus: 'Gerar Chave',
        titleSuccessStatus: 'Chave Gerada',
        description: 'Descrição da Chave',
        dateExpiryPlaceholder: 'Data de Expiração: 01-12-2021',
        tokenTitle: 'CHAVE',
        note: 'NOTA:',
        noteText: 'Copia e guarda este TOKEN, porque ele só aparece uma vez.',
        generateBtn: 'Gerar'
      },
      alert: {
        success: 'Chave gerado com sucesso.',
        getError: 'Não foi possível carregar as Chaves. Actualize a página.',
        removeSuccess: 'Chave Removido',
        error: 'Não foi possível efetuar esta operação',
        merchantDoesntMatch: 'A conta conectada não possui autorização',
        merchantWithoutActive: 'Comerciante não possui subscrição activa.',
        tokenIsInRequested: 'Chave está em estado de SOLICITADO',
        tokenNotFound: 'Chave não encontrada',
        merchantNotSync: 'Comerciante não Sincronizado com EMIS'
      },
      route: {
        listTitle: 'Chaves'
      }
    },
    filters: {
      requestStatus: {
        requested: 'Solicitado',
        active: 'Activo',
        suspended: 'Suspendido',
        processing: 'Processamento',
        creationFailed: 'Geração de Token falhou',
        sandbox: 'Testes'
      },
      transactionsStatus: {
        new: 'Processamento',
        initial: 'Inicial',
        accepted: 'Confirmado',
        rejected: 'Rejeitado'
      }
    },
    months: {
      1: 'Janeiro',
      2: 'Fevereiro',
      3: 'Março',
      4: 'Abril',
      5: 'Maio',
      6: 'Junho',
      7: 'Julho',
      8: 'Agosto',
      9: 'Setembro',
      10: 'Outubro',
      11: 'Novembro',
      12: 'Dezembro'
    },
    weeksCounts: {
      first: '1ª Semana',
      second: '2ª Semana',
      third: '3ª Semana',
      fourth: '4ª Semana'
    },
    mobileWeeks: {
      first: '1ª',
      second: '2ª',
      third: '3ª',
      fourth: '4ª'
    }
  }
}
