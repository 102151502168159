<template>
  <div class="container-login">
    <div class="vpos-container">
      <div class="box-login">
        <div class="box-login-header">
          <img src="../../../assets/images/icons/vPOS-logo.svg" alt="">
        </div>
        <div class="box-login-body">
          <div >
            <div class="col">
              <div class="form-group" >
                  <div class="col-md-12">
                    <label class="label-error"><span v-if="wrongCredentials">{{wrongCredentials}}</span></label>
                    <input type="text"
                    :class="{'form-control': true, 'is-input-danger': errors.has('form.username')}"
                    id="User-email"
                    name="form.username"
                    placeholder="usuario@vpos.com"
                    @focus="closeError"
                    v-model="form.username"
                    v-validate="'required'"
                    data-vv-as="username" />
                  </div>
                  <div class="error-box">
                    <span v-show="errors.has('form.username')" class="help is-danger">{{ errors.first('form.username') }}</span>
                  </div>
                </div>
            </div>
            <div class="col" >
                <div class="form-group" >
                  <div class="col-md-12">
                    <input type="password"
                    :class="{'form-control': true, 'is-input-danger': errors.has('form.password')}"
                    id="User-Password"
                    name="form.password"
                    placeholder="*******"
                    @focus="closeError"
                    v-model="form.password"
                    v-validate="'required'"
                    @keypress.enter="ProcessForm"
                    data-vv-as="senha" />
                  </div>
                  <div class="error-box">
                    <span v-show="errors.has('form.password')" class="help is-danger">{{ errors.first('form.password') }}</span>
                  </div>
                </div>
            </div>
            <div class="col side-login">
              <button class="vpos-btn success" @click="ProcessForm">
                <span v-if="!isRequesting">{{ $t('login.btnLogin') }}</span>
                <div class="loading-dots" v-if="isRequesting">
                  <div class="loading-dots--dot"></div>
                  <div class="loading-dots--dot"></div>
                  <div class="loading-dots--dot"></div>
                </div>
              </button>
            </div>
            <div class="col  reset-password">
              <router-link :to="{name: 'AdminResetPassword'}" tag="span">{{ $t('login.recoveryPassword') }}</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="lang-choose">
        <menu-language></menu-language>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import MenuLanguage from '@/components/BackOffice/Layouts/MenuLanguage.vue'

export default {
  name: 'Login',
  components: {
    'menu-language': MenuLanguage
  },
  data () {
    return {
      isRequesting: false,
      form: {
        username: '', // "admin",
        password: '' // "WeConnectToOlhaSo2018"
      },
      hadError: '',
      wrongCredentials: '',
      isVerifyng: false
    }
  },
  computed: {
    ...mapGetters('Admins', ['isLoggedIn'])
  },
  watch: {
    // whenever question changes, this function will run
    hadError: function () {
      this.isVerifyng = true
    },
    isVerifyng: function () {
      if (this.isVerifyng) {
        setTimeout(() => {
          this.hideMessage()
        }, 6000)
        setTimeout(() => {
          this.closeError()
        }, 12000)
      }
    }
  },
  methods: {
    /*
     * Map all the actions from the Admin module
     */
    ...mapActions('Admins', ['Login', 'GetUserDetails']),
    /*
     * ProcessForm: This method will validate the DoLogin() method
     */
    async ProcessForm () {
      const result = await this.$validator.validateAll()
      return result ? this.DoLogin() : result
    },
    /*
     * DoLogin: This method will execute the Login function from the
     * Admin module in vuex store.
     */
    async DoLogin () {
      this.closeError()
      this.isRequesting = true
      try {
        await this.Login(this.form)
        await this.GetUserDetails()
        this.$router.push({ name: 'Dashboard' })
      } catch (err) {
        this.HangleLoginErrors(err)
      }
      this.isRequesting = false
    },
    /**
     * HangleLoginErrors: It is a helper method that will
     * handle all the errors regarding the Login method.
     */
    HangleLoginErrors (error) {
      // Error messages
      const defaultErrorMessage = `Não foi possível realizar esta operação.`
      const loginError = this.$t('login.wrongCredentials')

      // Check for the error response if there's any
      if (error.response) {
        switch (error.response.data[0].code) {
          case 'INVALID_USERNAME_OR_PASSWORD':
            this.wrongCredentials = loginError
            break
          default:
            this.hadErrorUsername = defaultErrorMessage
        }
      } else {
        this.hadError = defaultErrorMessage
      }
    },
    closeError () {
      this.hadError = ''
      this.hadErrorUsername = ''
      this.hadErrorPassword = ''
    },
    hideMessage () {
      var element = document.querySelector('.error-container')
      element.classList.remove('fade-in')
      element.classList.toggle('fade-out')
    }
  },
  created () {
    // Check if the user is logged in, if so, redirect to the
    // admin area: Dashboard.
    if (this.isLoggedIn) {
      this.$router.push({ name: 'Dashboard' })
    }
  }
}
</script>
<style lang="scss" scoped>
.col{
  margin-bottom: 0;
}

.side-login{
  margin: 16px 0;
}

.box-login{
  height: 420px;
}

.label-error{
  width: 100%;
  height: 20px;
  display: flex;
  // border: 1px solid red;
  justify-content: flex-end;
}

.label-error span{
  color: red;
  font-family: 'Open Sans SemiBold';
}
</style>
