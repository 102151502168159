<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import '@/assets/css/style.scss'

export default {
  created () {}
}
</script>
<style lang="scss"></style>
