<template>
  <div class="c-file-input js-file-input">
    <label class="c-file-input__label js-file-input__label">
      <span>{{ label }}</span>
      <input class="c-file-input__field js-file-input__field" type="file" @change="PreviewFile" />
    </label>
    <div
      @click="valueModal = true"
      v-show="(imgSrc && images) || isEditing"
      class="c-file-input__remove js-file-input__remove"
    >
      <span class="c-file-input__remove__icon c-icon">
        <span class="icon icon-eye"></span>
      </span>
    </div>
    <div v-if="valueModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper" @click="valueModal = false">
            <div class="modal-dialog" @click.stop>
              <div class="modal-body">
                <img class="preview" v-if="!isEditing" :src="imgSrc" alt />
                <img class="preview" v-if="isEditing" :src="fileUrl" alt />
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    OnChange: { type: Function, required: true },
    disabled: { type: Boolean, default: false },
    isEditing: { type: Boolean, default: false },
    fileUrl: { type: String },
    images: { type: Boolean, default: true },
    typeFiles: { type: String, default: '.jpg, .png, .gif, .pdf, .svg' },
    index: { type: Number },
    defaultImage: { type: String },
    labelPlaceholder: { type: String, default: 'Escolher o Arquivo' }
  },
  data () {
    return {
      imgSrc: '',
      isRequesting: false,
      valueModal: false,
      valueName: ''
    }
  },
  methods: {
    PreviewFile (event) {
      // Clear the current image in the previewer
      this.imgSrc = this.defaultImage
      // Instantiate a new FileReader object.
      const Reader = new FileReader()
      const file = event.target.files[0]
      this.valueName = event.target.files[0].name
      // Read the file into the Reader object
      // in case there is an image selected.
      if (file) {
        Reader.readAsDataURL(file)
      }
      // Load the result from the Reader object into the
      // imgSrc state property.

      Reader.addEventListener(
        'load',
        () => {
          this.imgSrc = Reader.result
        },
        false
      )

      // Pass the input object to a method from the parent component
      this.OnChange(event.target.files[0], this.index)
    }
  },
  computed: {
    label () {
      return this.imgSrc ? this.valueName : this.labelPlaceholder
    }
  },
  mounted () {
    this.imgSrc = this.defaultImage
  }
}
</script>
<style lang="scss" scoped>
input {
  overflow: visible;
  width: 100%;
  height: 100%;
}
label {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 6px;
  padding-left: 16px;
}

.c-file-input {
  width: 100%;
  position: relative;
  display: flex;
  height: 30px;
  border: 1px solid #b1b1b1;
  border-radius: 5px;
  color: #9a9a9a;
}

.c-file-input__field {
  position: absolute !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  z-index: -1;
}
.c-file-input__remove.js-file-input__remove {
  display: flex;
}

.c-file-input__remove__icon.c-icon {
  display: flex;
  align-items: center;
}

.preview {
  background-repeat: no-repeat;
  width: 100%;
  height: 600px;
}

.icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: cover;
}

.icon-eye {
  background-image: url(../../../assets/images/icons/eye-default.png);
  margin-right: 8px;
}

.icon-eye:hover,
.icon-eye:focus {
  background-image: url(../../../assets/images/icons/eye-hover.png);
}

/** Modal CSS */
.modal-wrapper {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.16);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-content {
  padding: 32px;
}

.modal-header {
  border-bottom: 0;
  display: flex;
  justify-content: center;
}

.modal-header span {
  text-align: center;
  font-family: "Open Sans SemiBold";
  font-size: 18px;
  letter-spacing: 0;
  color: #595a5b;
  opacity: 1;
}
.modal-body {
  width: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-body .form-group {
  padding-right: 0;
  margin: 0;
  margin-bottom: 16px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-dialog {
  width: auto !important;
  max-width: 1800px;
  max-height: 600px;
  margin: 0;
}
</style>
