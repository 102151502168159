<template>
  <div>
    <div class="main-header">
      <div class="main-header__heading">
        <bread-crumbs></bread-crumbs>
      </div>
      <div class="col col-9 head-info">
          <span>
            <button @click="show" class="btn btn-primary">{{ $t('subscriptions.newSubscription.addBtn') }}</button>
          </span>
        <div class="float-btn plus" @click="show"><i class="fas fa-plus"></i></div>
      </div>
    </div>
    <div class="container">
      <Table
          :cols="cols"
          :data="subscriptions"
          title="Clientes"
          :searchMethod="GetSubscriptions"
          :pagination="pagination"
          :paginationMethod="GetSubscriptions"
          :sortMethod="GetSubscriptions"
          :changePage="changePage"
          resource="subscriptions"
          editRoute="EditSubscriptions"
          :pageCount="pageCount"
          :detailMethod="openDetail"
          :hasFilter="false"
        ></Table>
    </div>
    <modal name="do-subscription">
        <div class="top-right">
          <i class="fas fa-times" @click="$modal.hide('do-subscription')"></i>
        </div>
        <div class="vp-header">
          <span>{{ $t('subscriptions.newSubscription.modal.title') }}</span>
        </div>
        <div class="vp-body">
          <div class="col col-flex-input">
            <label for>{{$t('subscriptions.modal.newSubscription.type')}}</label>
            <multiselect
              v-model="type"
              :options="options"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              :placeholder="$t('subscriptions.newSubscription.modal.typePlaceholder')">
            </multiselect>
          </div>
          <label for>{{$t('subscriptions.modal.newSubscription.supportBank')}}</label>
          <multiselect
            v-model="formBank"
            :options="banks"
            @search-change="GetSubscriptions"
            @open="GetBanks"
            :clearOnSelect="true"
            @close="setBank(formBank)"
            :placeholder="$t('subscriptions.newSubscription.modal.bankNamePlaceholder')"
            label="name"
          ></multiselect>
          <div class="col col-flex-input">
            <label for>{{$t('subscriptions.modal.newSubscription.iban')}}</label>
            <div class="vpos-special-input">
              <span class="box-default">{{nibDefault}}</span>
              <input class="form-field" type="text" v-model="nib" placeholder="0000 0000 0000 0000 0 " v-mask="'#### #### #### #### #'">
            </div>
          </div>
        </div>
        <div class="vp-footer">
          <button class="btn btn-success" @click="SubscribeBanks" :disabled="isRequesting">
            <span v-if="!isRequesting">{{ $t('subscriptions.newSubscription.modal.subscribeBtn') }}</span>
            <div class="loading-dots" v-if="isRequesting">
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
            </div>
          </button>
        </div>
    </modal>
    <modal name="get-subscription">
        <div class="top-right">
          <i class="fas fa-times" @click="$modal.hide('get-subscription')"></i>
        </div>
        <div class="vp-body vpos-details-modal">
          <div class="vpos-details">
            <p>{{ $t('subscriptions.get.bankName') }}</p>
            <span>{{ subscription.bank['name'] ? subscription.bank['name'] : '' }}</span>
          </div>
          <div class="vpos-details">
            <p>{{ $t('subscriptions.get.merchantName')}}</p>
            <span>{{ subscription.merchant['name'] }}</span>
          </div>
          <div class="vpos-details">
            <p>{{$t('subscriptions.get.provider')}}</p>
            <div class="img-detail">
              <img v-if="!subscription.type" src="../../../assets/images/No_image_available.png" />
              <img v-else src="../../../assets/images/logo/Logo_MCX_Express_Provider_White.png" />
            </div>
          </div>
          <div class="vpos-details">
            <div class="vpos-details-status radius"
            :class="{'is-success-box': 'filters.requestStatus.active' === subscription.state,
              'is-secondary-box': 'filters.requestStatus.processing' === subscription.state,
              'is-secondary-box': 'filters.requestStatus.sandbox' === subscription.state,
              'is-primary-box': 'filters.requestStatus.requested' === subscription.state,
              'is-danger-box': 'filters.requestStatus.suspended' === subscription.state}">
              <i v-for="(subs, index) of subscriptionStatus" :key="index" v-show="subs.status === subscription.state" :class="subs.class"></i>
              </div>
          </div>
        </div>
    </modal>
    <div>
      <alert :text="$t('alert.success')" v-if="hadSuccess" :message="hadSuccess" type="toast--green"></alert>
      <alert :text="$t('alert.error')" v-if="hadError" :message="hadError" type="toast--red"></alert>
    </div>
  </div>
</template>
<script>
import Table from '@/components/BackOffice/Layouts/Table.vue'
import { mask } from 'vue-the-mask'

export default {
  components: {
    Table
  },
  directives: { mask },
  data () {
    return {
      subscriptions: [],
      banks: [],
      hadSuccess: '',
      hadError: '',
      nib: '',
      nibDefault: 'AO0000',
      isRequesting: false,
      form: {},
      formBank: {},
      subscription: {
        bank: {
          name: ''
        },
        merchant: {
          name: ''
        }
      },
      subscriptionStatus: [
        { status: 'filters.requestStatus.active', class: 'fas fa-check' },
        { status: 'filters.requestStatus.suspended', class: 'fas fa-times' },
        { status: 'filters.requestStatus.processing', class: 'far fa-clock' },
        { status: 'filters.requestStatus.requested', class: 'fas fa-exclamation' },
        { status: 'filters.requestStatus.sandbox', class: 'fas fa-clock' }
      ],
      pageCount: 4,
      hexTokens: {
        F: {
          pattern: /[0-9a-fA-O]/,
          transform: v => v.toLocaleUpperCase()
        }
      },
      type: '',
      options: ['Emis GPO'],
      x: window.matchMedia('(max-width: 480px)'),
      y: window.matchMedia('(max-width: 1024px)'),
      z: window.matchMedia('(max-width: 1500px)')
    }
  },
  computed: {
    cols () {
      if (this.x.matches) { // If media query matches
        return [
          { name: 'state', label: this.$t('subscriptions.table.labels.state') },
          { name: 'merchant_name', label: this.$t('subscriptions.table.labels.merchantName') },
          { name: 'type', label: this.$t('subscriptions.table.labels.type') }
        ]
      } else {
        return [
          { name: 'bank_name', label: this.$t('subscriptions.table.labels.bankName') },
          { name: 'state', label: this.$t('subscriptions.table.labels.state') },
          { name: 'merchant_name', label: this.$t('subscriptions.table.labels.merchantName') },
          { name: 'type', label: this.$t('subscriptions.table.labels.type') }
        ]
      }
    },
    pagination () {
      if (this.x.matches) { // If media query matches
        return {
          perPage: 7,
          pageable: { pageNumber: 0 },
          isFirstPage: false,
          isLastPage: false
        }
      } else {
        return {
          perPage: 10,
          pageable: { pageNumber: 0 },
          isFirstPage: false,
          isLastPage: false
        }
      }
    }
  },
  methods: {
    SelectImage (file) {
      this.form.file = file
    },
    /*
     *  GetSubscriptions: This method will fire a GET request
     *  to fetch the subscriptions and the will store the result
     *  into the orders local state property
     */
    async GetSubscriptions (type, sort = '', search = '', size) {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      var sorters = []
      if (sort) {
        sorters.push(sort)
      }
      if (type === 'next') {
        this.pagination.pageable.pageNumber += 1
      }

      if (type === 'prev') {
        this.pagination.pageable.pageNumber -= 1
      }

      // API query options like: sorts and pagination
      let query = ''
      query += `page=${this.pagination.pageable.pageNumber}`
      query += size ? `&size=${size}` : `&size=${this.pagination.perPage}`
      query += `&sort=createdDate,desc`

      try {
        const result = await this.axios.get(`/merchants/${merchantID}/subscriptions?${query}`)
        const res = result.data
        const r = res.data
        this.totalElements = r.items_count
        this.subscriptions = r.map(item => {
          item.bank_name = item.bank.name
          item.merchant_name = item.merchant.name
          item.state = this.$options.filters.requestStatus(item.state)
          // item.type = this.$options.filters.providerType(item.type)
          return item
        })
        this.pagination.isFirstPage = res.is_first_page
        this.pagination.isLastPage = res.is_last_page
        this.pageCount = res.total_pages
      } catch (e) {
        this.hadError = this.$t('subscriptions.alert.getAllError')
      }
      this.isRequesting = false
    },
    /**
     * GetSubscription: This method will fire a GET request and then
     * assign the response data into the state property: form
     */
    async GetSubscription (id) {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      try {
        const result = await this.axios.get(
          `/merchants/${merchantID}/subscriptions/${id}`
        )
        this.subscription = result.data
        this.subscription.state = this.$options.filters.requestStatus(this.subscription.state)
      } catch (e) {
        this.hadError = this.$t('subscriptions.alert.getError')
      }
      this.isRequesting = false
    },
    changePage (page) {
      this.pagination.pageable.pageNumber = page
    },
    show () {
      this.$modal.show('do-subscription')
    },
    hide () {
      this.$modal.hide('do-subscription')
    },
    showSubscription () {
      this.$modal.show('get-subscription')
    },
    hideSubscription () {
      this.$modal.hide('get-subscription')
    },
    openDetail (id) {
      this.GetSubscription(id)
      this.showSubscription()
    },
    /**
     *  GetBanks: This method will request all the providers available
     *  and store the response in the state property: providers.
     */
    async GetBanks () {
      this.isRequesting = true
      try {
        const result = await this.axios.get('/banks')
        this.banks = result.data
      } catch (e) {
        this.hadError = this.$t('subscriptions.alert.getBanksError')
      }
      this.isRequesting = false
    },
    /**
     * CreateSubscriptionError: This method will handle all the errors related
     * to the signup process.
     *
     * @param {object} error
     * */
    CreateSubscriptionError (error) {
      const defaultErrorMessage = this.$t('subscriptions.alert.error')
      if (error.response) {
        switch (error.response.data[0].code) {
          case 'MERCHANT_NOT_FOUND':
            this.hadError = this.$t('subscriptions.alert.merchantNotFound')
            break
          case 'MERCHANT_NOT_VALID_FOR_ACCOUNT':
            this.hadError = this.$t('subscriptions.alert.merchantNotValidForAccount')
            break
          case 'BANK_NOT_FOUND':
            this.hadError = this.$t('subscriptions.alert.banckNotFound')
            break
          case 'MERCHANT_NOT_ACTIVE':
            this.hadError = this.$t('subscriptions.alert.merchantNotActive')
            break
          case 'SUBSCRIPTION_ALREADY_EXIST':
            this.hadError = this.$t('subscriptions.alert.subscriptionAlreadyExist')
            break
          default:
            this.hadError = defaultErrorMessage
            break
        }
      } else {
        this.hadError = defaultErrorMessage
      }
    },
    /**
     *  SubscribeBanks: This method will request all the providers available
     *  and store the response in the state property: providers.
     */
    async SubscribeBanks () {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      this.form.bank_id = this.formBank.id
      this.form.iban = this.nibDefault + this.myTrim(this.nib)
      try {
        await this.axios.post(`/merchants/${merchantID}/subscriptions`, this.form)
        this.hadSuccess = this.$t('subscriptions.alert.success')
        this.GetSubscriptions()
      } catch (e) {
        this.CreateSubscriptionError(e)
      }
      this.hide()
      this.isRequesting = false
      setTimeout(() => {
        this.hadSuccess = ''
        this.hadError = ''
      }, 8000)
    },
    setBank (val = null) {
      this.nibDefault = val.iban_initial
    },
    myTrim (x) {
      return x.replace(/[^\d]/g, '')
    }
  },
  mount () {
    this.show()
  },
  created () {
    this.GetSubscriptions()
    this.userName = localStorage.getItem('vpos_body_name') ? localStorage.getItem('vpos_body_name') : 'Kevin Durant'
  }
}
</script>
<style style="scss" scoped>

input{
  height: 40px;
  margin-top: 20px;
}

.col{
  padding: 0;
}

</style>
