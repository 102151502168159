<template>
  <div>
    <div class="main-header">
      <div class="main-header__heading">
        <bread-crumbs></bread-crumbs>
      </div>
        <div class="col col-9 head-info">
          <span>
            <button @click="OpenGenerateRefundCode" class="btn btn-primary"> <i class="fas fa-cog"></i> {{$t('operators.table.refundCodes.btnGenerated')}}</button>
          </span>
        <div class="float-btn plus" @click="OpenGenerateRefundCode"><i class="fas fa-plus"></i></div>
      </div>
    </div>
    <div class="container">
      <Table
          :cols="cols"
          :data="tokens"
          title="tokens"
          :searchMethod="GetRefundCodes"
          :pagination="pagination"
          :paginationMethod="GetRefundCodes"
          :sortMethod="GetRefundCodes"
          :changePage="ChangePage"
          resource="tokens"
          editRoute="EditTokens"
          :searchBar="false"
          :hasPagination="false"
          :pageCount="pageCount"
          :detailMethod="OpenDeleteRefundCode"
        ></Table>
    </div>
    <modal name="generate-refund-code">
       <div class="top-right">
          <i class="fas fa-times" @click="$modal.hide('generate-refund-code')"></i>
       </div>
        <div class="vp-header">
          <span v-if="!refundCodeCreated">{{$t('operators.modalGenerate.initialGenerate')}}</span>
          <span v-else>{{$t('operators.modalGenerate.doneGenerate')}}</span>
        </div>
        <div class="vp-footer" v-if="!refundCodeCreated">
          <button class="btn btn-success" @click="GenerateRefundCode" :disabled="isRequesting">
            <span v-if="!isRequesting">{{$t('operators.modalGenerate.generate')}}</span>
            <div class="loading-dots" v-if="isRequesting">
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
            </div>
          </button>
        </div>
    </modal>
    <modal name="delete-refund-code">
       <div class="top-right">
          <i class="fas fa-times" @click="$modal.hide('delete-refund-code')"></i>
       </div>
        <div class="vp-header">
          <span>{{$t('operators.modalDelete.question')}}</span>
        </div>
        <div class="vp-footer">
          <button class="btn btn-danger mg-bottom" @click="DeleteRefundToken" :disabled="isRequesting">
            <span v-if="!isRequesting">{{$t('operators.modalDelete.btnRemove')}}</span>
            <div class="loading-dots" v-if="isRequesting">
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
            </div>
          </button>
          <button class="btn btn-outline-primary" @click="hideDeleteRefundToken" >
            <span>{{$t('operators.modalDelete.cancel')}}</span>
          </button>
        </div>
    </modal>
    <div>
      <alert :text="$t('alert.success')" v-if="hadSuccess" :message="hadSuccess" type="toast--green"></alert>
      <alert :text="$t('alert.error')" v-if="hadError" :message="hadError" type="toast--red"></alert>
    </div>
  </div>
</template>
<script>
import Table from '@/components/BackOffice/Layouts/Table.vue'
import { pt } from 'vuejs-datepicker/dist/locale'
var state = {
  disabledDates: {
    to: new Date()
  }
}
export default {
  props: {
    id: {
      type: String
    }
  },
  components: {
    Table
  },
  data () {
    return {
      form: {},
      formCreated: {},
      refundCodeCreated: false,
      tokens: [],
      ptPT: pt,
      hadSuccess: '',
      hadError: '',
      isRequesting: false,
      deleteID: '',
      transaction: {
        operator: {
          'first_name': '',
          'last_name': ''
        }
      },
      pageCount: 4,
      disabledDates: state,
      x: window.matchMedia('(max-width: 480px)'),
      y: window.matchMedia('(max-width: 1024px)'),
      z: window.matchMedia('(max-width: 1500px)')
    }
  },
  computed: {
    PT () {
      return pt
    },
    cols () {
      if (this.x.matches) { // If media query matches
        return [
          { name: 'code', label: this.$t('operators.table.refundCodes.labels.code') },
          { name: 'enabled', label: this.$t('operators.table.refundCodes.labels.enabled') },
          { name: 'expiration_date', label: this.$t('operators.table.refundCodes.labels.expirationDate') }
        ]
      } else {
        return [
          { name: 'code', label: this.$t('operators.table.refundCodes.labels.code') },
          { name: 'enabled', label: this.$t('operators.table.refundCodes.labels.enabled') },
          { name: 'created_date', label: this.$t('operators.table.refundCodes.labels.cratedDate') },
          { name: 'expiration_date', label: this.$t('operators.table.refundCodes.labels.expirationDate') }
        ]
      }
    },
    pagination () {
      if (this.x.matches) { // If media query matches
        return {
          perPage: 7,
          pageable: { pageNumber: 0 },
          isFirstPage: false,
          isLastPage: false
        }
      } else {
        return {
          perPage: 10,
          pageable: { pageNumber: 0 },
          isFirstPage: false,
          isLastPage: false
        }
      }
    }
  },
  methods: {
    ChangePage (page) {
      this.pagination.pageable.pageNumber = page
    },
    /*
     *  GetRefundCodes: This method will fire a GET request
     *  to fetch the tokens and the will store the result
     *  into the orders local state property
     */
    async GetRefundCodes (type, sort = '', search = '', size) {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      // var today = new Date()
      let query = ''
      query += `sort=createdDate,desc`

      try {
        const result = await this.axios.get(`/merchants/${merchantID}/operators/${this.id}/refund-codes?${query}`)
        const res = result.data
        const r = res.data
        this.totalElements = r.items_count
        this.tokens = r.map(item => {
          item.state = this.$options.filters.requestStatus(item.state)
          item.created_date = this.$options.filters.isDate(item.created_date)
          item.expiration_date = this.$options.filters.isDate(item.expiration_date)
          item.enabled = this.$options.filters.refundCodeStatus(item.enabled)
          return item
        })
        this.pagination.isFirstPage = res.is_first_page
        this.pagination.isLastPage = res.is_last_page
        this.pageCount = res.total_pages
      } catch (e) {
        this.hadError = this.$t('operators.alert.getAllError')
      }
      this.isRequesting = false
    },
    /**
     * GenerateToken: This method will fire a POST request
     */
    async GenerateRefundCode () {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      try {
        const result = await this.axios.post(
          `/merchants/${merchantID}/operators/${this.id}/refund-codes`)
        this.refundCodeCreated = true
        this.formCreated = result.data
        this.hadSuccess = this.$t('operators.alert.success')
      } catch (e) {
        this.TokenError(e)
      }
      // this.hideGenerateToken()
      this.isRequesting = false
      this.hideGenerateRefundCode()
      this.GetRefundCodes()
      setTimeout(() => {
        this.hadSuccess = ''
        this.hadError = ''
      }, 6000)
    },
    /**
     * DeleteRefundToken: This method will fire a DELETE request
     */
    async DeleteRefundToken () {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      try {
        await this.axios.delete(
          `/merchants/${merchantID}/operators/${this.id}/refund-codes/${this.deleteID}`
        )
        this.hadSuccess = this.$t('operators.alert.remove')
        this.GetRefundCodes()
      } catch (e) {
        this.TokenError(e)
      }
      this.hideDeleteRefundToken()
      this.isRequesting = false
      setTimeout(() => {
        this.hadSuccess = ''
        this.hadError = ''
      }, 6000)
    },
    /**
     * TokenError: This method will handle all the errors related
     * to the generate token process.
     *
     * @param {object} error
     * */
    TokenError (error) {
      const defaultErrorMessage = `Não foi possível efetuar esta operação`
      if (error.response) {
        switch (error.response.data[0].code) {
          case 'MERCHANT_ID_DOES_NOT_MATCH_AUTHENTICATED_ACCOUNT':
            this.hadError = 'A conta conectada não possui autorização'
            break
          case 'MERCHANT_WITHOUT_ACTIVE_SUBSCRIPTION':
            this.hadError = 'Comerciante não possui subscrição activa.'
            break
          case 'TOKEN_IS_IN_REQUESTED_STATE':
            this.hadError = 'Token está em estado de SOLICITADO'
            break
          case 'TOKEN_NOT_FOUND':
            this.hadError = 'Token não encontrado'
            break
          case 'MERCHANT_NOT_SYNCHRONIZED_WITH_EMIS':
            this.hadError = 'Comerciante não Sincronizado com EMIS'
            break
          default:
            this.hadError = defaultErrorMessage
            break
        }
      } else {
        this.hadError = defaultErrorMessage
      }
    },
    OpenGenerateRefundCode () {
      this.$modal.show('generate-refund-code')
    },
    hideGenerateRefundCode () {
      this.$modal.hide('generate-refund-code')
      this.refundCodeCreated = false
    },
    OpenDeleteRefundCode (id) {
      this.$modal.show('delete-refund-code')
      this.deleteID = id
    },
    hideDeleteRefundToken () {
      this.$modal.hide('delete-refund-code')
      this.refundCodeCreated = false
    }
  },
  created () {
    this.userName = localStorage.getItem('vpos_body_name') ? localStorage.getItem('vpos_body_name') : 'Kevin Durant'
    this.GetRefundCodes()
  }
}
</script>
<style style="scss" scoped>
.mg-bottom{
  margin-bottom: 20px;
}

.vdp-datepicker{
  width: 100%;
}

.token-first-input{
  margin-bottom: 20px;
}

.red-note{
  color: red;
  font-size: 14px;
  text-align: center;
}
</style>
