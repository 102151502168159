<template>
<div class="grid-container">
  <div class="menu-icon" @click="openToggle">
    <i class="fas fa-bars"></i>
  </div>
  <vpos-header></vpos-header>
  <vpos-aside></vpos-aside>
  <vpos-main></vpos-main>
  <vpos-footer></vpos-footer>
</div>
</template>
<script>
import VposHeader from '@/components/BackOffice/Layouts/Vpos-Header.vue'
import VposAside from '@/components/BackOffice/Layouts/Vpos-Aside.vue'
import VposMain from '@/components/BackOffice/Layouts/Vpos-Main.vue'
import VposFooter from '@/components/BackOffice/Layouts/Vpos-Footer.vue'

export default {
  components: {
    'vpos-aside': VposAside,
    'vpos-header': VposHeader,
    'vpos-main': VposMain,
    'vpos-footer': VposFooter
  },
  data () {
    return {
      isActive: false
    }
  },
  methods: {
    openToggle () {
      var element = document.querySelector('.sidenav')
      element.classList.toggle('active')
    }
  }
}
</script>
<style lang="scss" scoped>

.general-app{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  position: absolute;
}

.right-side{
  display: flex;
  flex-grow: 1;
  // border: 1px solid yellow;
  flex-direction: column;
  overflow-y: auto;
}
</style>
