<template>
  <aside class="sidenav">
    <div class="sidenav__close-icon" @click="openToggle">
      <i class="fas fa-times"></i>
    </div>
    <div class="sidenav-header">
        <img src="../../../assets/images/icons/vPOS-logo.svg" alt="">
    </div>
    <ul class="sidenav__list">
      <div class="sidenav-subheader">
          <span>{{ merchantName }}</span>
      </div>
      <router-link class="sidenav__list-item" :to="{name: 'Dashboard'}" tag="li"><i class="fas fa-tachometer-alt"></i><span>{{$t('navigationDrawer.menu.subMenu1')}}</span></router-link>
      <router-link class="sidenav__list-item" :to="{name: 'ListSubscriptions'}" tag="li"><i class="fas fa-pen-fancy"></i><span>{{$t('navigationDrawer.menu.subMenu2')}}</span></router-link>
      <router-link class="sidenav__list-item" :to="{name: 'ListOperators'}" tag="li"><i class="fas fa-user"></i><span>{{$t('navigationDrawer.menu.subMenu3')}}</span></router-link>
      <router-link class="sidenav__list-item" :to="{name: 'ListTransitions', params: {type: 't'}}" tag="li"><i class="fas fa-exchange-alt"></i><span>{{$t('navigationDrawer.menu.subMenu4')}}</span></router-link>
      <collapse :title="$t('navigationDrawer.menu.subMenu5.title')">
        <router-link class="sidenav__list-item" :to="{name: 'ListTokens'}" tag="li"><i class="fas fa-key"></i><span>{{$t('navigationDrawer.menu.subMenu5.token')}}</span></router-link>
        <!-- <router-link class="sidenav__list-item" :to="{name: 'ListSupervisors'}" tag="li"><i class="fas fa-key"></i><span>Código de Supervisor</span></router-link> -->
      </collapse>
      <ul class="sidenav__out">
        <li><menu-language></menu-language></li>
        <li class="sidenav__list-item" @click="DoLogout"><div class="sidenav__list__item-out"><i class="fas fa-sign-out-alt" aria-hidden="true"></i><span>{{$t('navigationDrawer.menu.subMenu6')}}</span></div></li>
      </ul>
    </ul>
  </aside>
</template>
<script>
import { mapActions } from 'vuex'
import Collapse from '@/components/BackOffice/Layouts/Collapse.vue'
import MenuLanguage from '@/components/BackOffice/Layouts/MenuLanguage.vue'
export default {
  components: {
    'collapse': Collapse,
    'menu-language': MenuLanguage
  },
  data () {
    return {
      merchantName: ''
    }
  },
  methods: {
    /*
     * Map all the actions from the Admin module
     */
    ...mapActions('Admins', ['Logout']),
    openToggle () {
      var element = document.querySelector('.sidenav')
      element.classList.remove('active')
    },
    async DoLogout () {
      this.Logout()
      setTimeout(() => {
        this.$router.push({ name: 'Login' })
      }, 1500)
    }
  },
  created () {
    this.merchantName = localStorage.getItem('vpos_body_merchant_name') ? localStorage.getItem('vpos_body_merchant_name') : 'Kevin Durant'
  }
}
</script>
<style lang="scss" scoped>

/* Mobile-first side nav styles */
.sidenav {
  grid-area: sidenav;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 220px;
  position: fixed;
  overflow-y: auto;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  z-index: 2; /* Needs to sit above the hamburger menu icon */
  background-color: #11559C;
  transform: translateX(-245px);
  transition: all 0.6s ease-in-out;
}

/* The active class is toggled on hamburger and close icon clicks */
.sidenav.active {
  transform: translateX(0);
}

/* Only visible on mobile screens */
.sidenav__close-icon {
  position: absolute;
  visibility: visible;
  top: 8px;
  right: 12px;
  cursor: pointer;
  font-size: 20px;
  color: #11559C;
}

.sidenav__out span{
    font-family: 'Open Sans SemiBold';
    font-size: 16px;
    color: #fff;
}

.sidenav-header{
    width: 100%;
    height: 85px;
    position: fixed;
    top: 0;
    z-index: -1;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidenav-header img{
    width: 70px;
}

.sidenav-subheader{
    width: 100%;
    height: 80px;
    top: 0;
    z-index: -1;
    background-color: #2196F3;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.sidenav-subheader span{
    font-family: 'Open Sans Bold';
    font-size: 16px;
    color: #fff;
}

ul {
  list-style-type: none;
}

.sidenav__out{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  list-style-type: none;
}

.sidenav__out li:first-child{
  &:hover{
    background-color: transparent;
  }
}

.sidenav__out .sidenav__list-item {
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.config-side{
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  cursor:default
}

.config-side:hover{
  background-color: transparent;
}

/* Non-mobile styles for side nav responsiveness, 750px breakpoint */
@media only screen and (min-width: 46.875em) {
  .sidenav {
    position: fixed; /* Fixed position on mobile */
    transform: translateX(0);
  }

  .sidenav__close-icon {
    visibility: hidden;
  }
}
</style>
