<template>
  <div>
    <div class="main-header">
      <div class="main-header__heading">Estorno</div>
      <div class="col col-9">
          <router-link :to="{name: 'RegistGPO'}" tag="span">
                <button class="btn btn-primary">Novo Estorno</button>
          </router-link>
        <div class="float-btn plus"><i class="fas fa-plus"></i></div>
      </div>
    </div>
    <div class="container">
      <Table
          :cols="cols"
          :data="clients"
          title="Clientes"
          :searchMethod="getClients"
          :pagination="pagination"
          :paginationMethod="getClients"
          :sortMethod="getClients"
          :changePage="changePage"
          resource="clients"
          editRoute="AdminEditClients"
          :pageCount="pageCount"
        ></Table>
    </div>
  </div>
</template>
<script>
import Table from '@/components/BackOffice/Layouts/Table.vue'

export default {
  components: {
    Table
  },
  data () {
    return {
      exampleBool: '',
      clients: [],
      hadError: '',
      isRequesting: false,
      form: {},
      cols: [
        { name: 'id', label: '#' },
        { name: 'first_name', label: 'Primeiro Nome' },
        { name: 'last_name', label: 'Último Nome' },
        { name: 'email', label: 'Email' },
        { name: 'identity_card_number', label: 'Bilhete de Identidade' },
        { name: 'phone_number', label: 'Número de Telemóvel' }
      ],
      pagination: {
        perPage: 12,
        pageable: { pageNumber: 0 }
      },
      pageCount: 4
    }
  },
  methods: {
    SelectImage (file) {
      this.form.file = file
    },
    changePage (page) {
      this.pagination.pageable.pageNumber = page
    }
  }
}
</script>
<style style="scss" scoped></style>
