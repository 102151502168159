import axios from 'axios'

const Login = async ({ commit }, form) => {
  try {
    const result = await axios.post('merchants/sessions', form)
    const token = result.data.token
    // Save data to the localstorage
    localStorage.setItem('user_token', token)
    axios.defaults.headers.common['X-Auth-Token'] = token
    return Promise.resolve(result.data)
  } catch (e) {
    return Promise.reject(e)
  }
}

const GetUserDetails = async ({ commit }, form) => {
  try {
    const result = await axios.get(`merchants/sessions/` + localStorage.getItem('user_token'))
    const id = result.data.id
    const name = result.data.name
    const merchantID = result.data.merchant['id']
    const merchantName = result.data.merchant['name']
    // Commit data
    commit('SET_ADMIN', result.data)
    // Save data to the localstorage
    localStorage.setItem('vpos_merchant', JSON.stringify(result.data))
    localStorage.setItem('vpos_body_id', id)
    localStorage.setItem('vpos_body_name', name)
    localStorage.setItem('vpos_body_merchant_id', merchantID)
    localStorage.setItem('vpos_body_merchant_name', merchantName)

    return Promise.resolve(result.data)
  } catch (e) {
    return Promise.reject(e)
  }
}

const SetAdmin = ({ commit }, admin) => {
  commit('SET_ADMIN', admin)
  axios.defaults.headers.common['X-Auth-Token'] = 'Bearer ' + admin.token
}

/*
 *  Logout: This method will erase all the user information from:
 *  localstorare, current state and the token in the axios headers
 */
const Logout = ({ commit }) => {
  try {
    // Clean the state
    commit('SET_ADMIN', {})
    // Clear the localstorage
    localStorage.removeItem('vpos_merchant')
    localStorage.removeItem('user_token')
    localStorage.removeItem('vpos_body_id')
    localStorage.removeItem('vpos_body_name')
    localStorage.removeItem('vpos_body_merchant_id')
    localStorage.removeItem('vpos_body_merchant_name')
    // Clear the auth headers
    axios.defaults.headers.common['X-Auth-Token'] = ''
    Promise.resolve(true)
  } catch (e) {
    Promise.reject(e)
  }
}

export default {
  namespaced: true,
  state: {
    user: { roles: [] }
  },
  getters: {
    nameInitials: state => {
      if (state.user.hasOwnProperty('name')) {
        const name = state.user.name.split(' ')
        if (name.length > 1) {
          return name[0][0].toUpperCase() + name[1][0].toUpperCase()
        } else {
          return name[0][0].toUpperCase()
        }
      } else {
        return ''
      }
    },
    isLoggedIn: state => {
      return !!state.user.hasOwnProperty('token')
    },
    /**
     * isSystemAdmin: This getter will check if the user is a system admin.
     *
     * @param {object} state
     * @returns {boolean}
     */
    isSystemAdmin: state => {
      const role = state.user.roles.find(role => role === 'SYSTEM_ADMIN')
      return !!role
    },
    /**
     * isAdmin: This getter will check if the user is an admin.
     *
     * @param {object} state
     * @returns {boolean}
     */
    isAdmin: state => {
      const role = state.user.roles.find(role => role === 'ADMIN')
      return !!role
    }
  },
  mutations: {
    SET_ADMIN (state, user) {
      state.user = user
    }
  },
  actions: {
    Login,
    Logout,
    SetAdmin,
    GetUserDetails
  }
}
