<template>
  <div>
    <div class="main-header">
      <div class="main-header__heading">Novo Token</div>
      <div class="col col-9 head-info">
        <button class="btn btn-success" @click="processForm" :disabled="isRequesting">
          <span v-if="!isRequesting"> Gerar Token</span>
          <div class="loading-dots" v-if="isRequesting">
            <div class="loading-dots--dot"></div>
            <div class="loading-dots--dot"></div>
            <div class="loading-dots--dot"></div>
          </div>
        </button>
        <div class="float-btn" @click="processForm">
          <i v-if="!isRequesting" class="fas fa-save"></i>
          <div v-if="isRequesting" class="loader-5 center"><span></span></div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="form-container">
        <div class="row">
          <div class="col col-4">
            <label for>Nome</label>
            <input
            type="text"
            v-model="form.name"
            name="name"
            placeholder="Joao Pessoa"
            class="form-control regist"
            :class="{'is-input-danger': errors.has('name')}"
            id="name"
            v-validate="'required'"
            data-vv-as="Nome"
            />
            <span
                v-show="errors.has('name')"
                class="help is-danger"
              >{{ errors.first('name') }}</span>
          </div>
          <div class="col col-4">
            <label for>NIF</label>
            <input
            type="text"
            v-model="form.nif"
            name="nif"
            placeholder="005123456789LA047"
            :class="{'is-input-danger': errors.has('nif')}"
            id="nif"
            maxlength="14"
            minlength="10"
            v-validate="'required|min:10|max:14'"
            data-vv-as="NIF"
            />
            <span
                v-show="errors.has('nif')"
                class="help is-danger"
              >{{ errors.first('nif') }}</span>
          </div>
          <div class="col col-4">
            <label for>Codigo de Operador</label>
            <input
            type="text"
            v-model="form.nif"
            name="nif"
            placeholder="888990"
            :class="{'is-input-danger': errors.has('nif')}"
            id="nif"
            maxlength="14"
            minlength="10"
            v-validate="'required|min:10|max:14'"
            data-vv-as="NIF"
            />
            <span
                v-show="errors.has('nif')"
                class="help is-danger"
              >{{ errors.first('nif') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <alert :text="$t('alert.success')" v-if="hadSuccess" :message="hadSuccess" type="toast--green"></alert>
      <alert :text="$t('alert.error')" v-if="hadError" :message="hadError" type="toast--red"></alert>
    </div>
  </div>
</template>
<script>
import Alert from '@/components/BackOffice/Layouts/Alert.vue'

export default {
  components: {
    'alert': Alert
  },
  data () {
    return {
      form: {},
      datas: {},
      updatedForm: {},
      hadSuccess: '',
      hadError: '',
      isRequesting: false
    }
  },
  methods: {
    /*
    *  ProcessForm: This method will validate the form using vee-validate
    *  component and then call the action method defined for this view
    *  if everything passes the validation.
    */
    async processForm () {
      this.hadError = ''
      const result = await this.$validator.validateAll()
      return result ? this.GenerateToken() : result
    },
    /**
     *  EditProfile: This method will request all the providers available
     *  and store the response in the state property: providers.
     */
    async GenerateToken () {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      try {
        await this.axios.patch(`/merchants/${merchantID}s`, this.form)
        this.hadSuccess = 'Token gerado com sucesso'
        this.GetSubscriptions()
      } catch (e) {
        this.hadError = 'Não foi possível gerar token'
      }
      this.isRequesting = false
      setTimeout(() => {
        this.hadSuccess = ''
        this.hadError = ''
      }, 2000)
    }
  },
  created () {
    this.userName = localStorage.getItem('vpos_body_name') ? localStorage.getItem('vpos_body_name') : 'Kevin Durant'
  }
}
</script>
<style style="scss" scoped></style>
