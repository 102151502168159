<template>
  <div>
    <div class="main-header">
      <div class="main-header__heading">Bancos</div>
      <div class="col col-9">
          <!-- <router-link :to="{name: 'RegistGPO'}" tag="span">
                <button class="btn btn-primary">Novo Banco</button>
          </router-link> -->
        <div class="float-btn plus"><i class="fas fa-plus"></i></div>
      </div>
    </div>
    <div class="container">
      <Table
          :cols="cols"
          :data="banks"
          title="Bancos"
          :searchMethod="GetBanks"
          :pagination="pagination"
          :paginationMethod="GetBanks"
          :sortMethod="GetBanks"
          :changePage="changePage"
          resource="banks"
          editRoute="AdminEditClients"
          :pageCount="pageCount"
        ></Table>
    </div>
  </div>
</template>
<script>
import Table from '@/components/BackOffice/Layouts/Table.vue'

export default {
  components: {
    Table
  },
  data () {
    return {
      exampleBool: '',
      banks: [],
      hadError: '',
      isRequesting: false,
      form: {},
      cols: [
        { name: 'name', label: 'Nome' },
        { name: 'acronym', label: 'Acronimo' }
      ],
      pagination: {
        perPage: 12,
        pageable: { pageNumber: 0 }
      },
      pageCount: 4
    }
  },
  methods: {
    SelectImage (file) {
      this.form.file = file
    },
    /*
     *  GetBanks: This method will fire a GET request
     *  to fetch the subscriptions and the will store the result
     *  into the orders local state property
     */
    async GetBanks (type, sort = '', search = '', size) {
      this.isRequesting = true
      var sorters = []
      if (sort) {
        sorters.push(sort)
      }
      if (type === 'next') {
        this.pagination.pageable.pageNumber += 1
      }

      if (type === 'prev') {
        this.pagination.pageable.pageNumber -= 1
      }

      // API query options like: sorts and pagination
      // let query = ''
      // query += `pageNumber=${this.pagination.pageable.pageNumber}`
      // query += size ? `&pageSize=${size}` : `&pageSize=${this.pagination.perPage}`
      // query += sort ? `&sorters=${sorters}` : ''
      // query += search ? `&search=${search}` : ''

      try {
        const result = await this.axios.get(`/banks`)
        const res = result.data
        this.totalElements = res.items_count
        this.banks = res

        this.pageCount = res.pages_count
      } catch (e) {
        this.hadError =
          'Não foi possível carregar as encomendas. Actualize a página.'
      }
      this.isRequesting = false
    },
    changePage (page) {
      this.pagination.pageable.pageNumber = page
    }
  },
  created () {
    this.GetBanks()
  }
}
</script>
<style style="scss" scoped></style>
