import Vue from 'vue'
import VueAxios from 'vue-axios'
import VModal from 'vue-js-modal'
import Multiselect from 'vue-multiselect'
import VueTelInput from 'vue-tel-input'
import VueTheMask from 'vue-the-mask'
import VueScreen from 'vue-screen'
import Datepicker from 'vuejs-datepicker'
import Breadcrumbs from '@/components/BackOffice/Layouts/Breadcrumbs.vue'
import Alert from '@/components/BackOffice/Layouts/Alert.vue'
import pt from 'vee-validate/dist/locale/pt_PT'
import en from 'vee-validate/dist/locale/en'
import VeeValidate, { Validator } from 'vee-validate'
import JsonExcel from 'vue-json-excel'

import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import i18n from './plugins/i18n'
import { Settings } from 'luxon'
import moment from 'moment'
import filters from './filters'

// You need a specific loader for CSS files
import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@/plugins/echarts'

axios.defaults.baseURL = process.env.VUE_APP_API
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

// Get Token evertime we realod the page
const token = localStorage.getItem('user_token')
if (token) {
  axios.defaults.headers.common['X-Auth-Token'] = token
}

// Components
Vue.use(VeeValidate)
Vue.use(VueAxios, axios)
Vue.use(VueScreen)
Vue.use(VModal)
Vue.use(VueTelInput)
Vue.use(VueTheMask)

// Set libraries
moment.locale(localStorage.getItem('currentLanguage') === 'pt' ? 'pt_PT' : 'en')
Validator.localize(localStorage.getItem('currentLanguage') === 'pt' ? 'pt_PT' : 'en', localStorage.getItem('currentLanguage') === 'pt' ? pt : en)

// register component to use
Vue.component('vuejs-datepicker', Datepicker)
Vue.component('multiselect', Multiselect)
Vue.component('bread-crumbs', Breadcrumbs)
Vue.component('alert', Alert)
Vue.component('downloadExcel', JsonExcel)

Settings.defaultLocale = localStorage.getItem('currentLanguage') === 'pt' ? pt : en

// Other Configurations
Vue.config.productionTip = true
filters(Vue)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
