<template>
    <div class="container-language">
      <div class="language-choose" @click="showBobbly = !showBobbly" v-click-outside="hide">
        <span>{{langText}}</span>
      </div>
      <div class="speech-bubble" :class="{ 'show-bobbly' : !showBobbly}" >
        <ul>
            <li @click.prevent="handleLocale('pt')" class="lang-row">
                <span class="lang-title">PT</span>
            </li>
            <li @click.prevent="handleLocale('en')" class="lang-row">
                <span class="lang-title">EN</span>
            </li>
        </ul>
      </div>
    </div>
</template>
<script>
import ClickOutside from 'vue-click-outside'
import { Validator } from 'vee-validate'
import pt from 'vee-validate/dist/locale/pt_PT'
import en from 'vee-validate/dist/locale/en'

export default {
  data () {
    return {
      showBobbly: true,
      langDefaultText: '',
      langText: ''
    }
  },
  methods: {
    handleLocale (locale) {
      window.localStorage.setItem('currentLanguage', locale)
      this.$i18n.locale = locale
      this.langDefaultText = locale
    },
    hide () {
      this.showBobbly = true
    },
    getBrowserLocales (options = {}) {
      const defaultOptions = {
        languageCodeOnly: false
      }

      const opt = {
        ...defaultOptions,
        ...options
      }

      const browserLocales =
        navigator.languages === undefined
          ? [navigator.language]
          : navigator.languages

      if (!browserLocales) {
        return undefined
      }

      return browserLocales.map(locale => {
        const trimmedLocale = locale.trim()

        return opt.languageCodeOnly
          ? trimmedLocale.split(/-|_/)[0]
          : trimmedLocale
      })
    }
  },
  watch: {
    langDefaultText (val) {
      this.langText = val === 'pt' ? 'PT' : 'EN'
      Validator.localize(localStorage.getItem('currentLanguage') === 'pt' ? 'pt_PT' : 'en', localStorage.getItem('currentLanguage') === 'pt' ? pt : en)
    }
  },
  created () {
    var langs = this.getBrowserLocales({ languageCodeOnly: true })

    if (window.localStorage.getItem('currentLanguage')) {
      this.handleLocale(window.localStorage.getItem('currentLanguage'))
    } else {
      this.handleLocale(langs[0])
    }
  },
  // do not forget this section
  directives: {
    ClickOutside
  }
}
</script>
<style lang="scss" scoped>
$target: if(true, "&", "");

ul {
  width: 100%;
  list-style-type: none;
}

.container-language{
  display: flex;
  align-items: center;
  justify-content: center;
}

.language-choose{
  width: 30px;
  height: 30px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  color: #11559C;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.language-choose span{
  font-family: 'Open Sans SemiBold';
}

.speech-bubble {
  position: absolute;
  background: #ffffff;
  border-radius: .4em;
  display: none;
}

.speech-bubble.show-bobbly {
  width: 50px;
  top: -70px;
  display: block;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-top-color: #ffffff;
  border-bottom: 0;
  margin-left: -9px;
  margin-bottom: -9px;
}

.speech-bubble span {
  padding: 0;
  color: #11559C;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lang-row{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.lang-title{
  font-family: 'Open Sans SemiBold';
}

.lang-row:hover{
  background-color: #11559C;
  cursor: pointer;
}

.lang-row:hover .lang-title{
  color: #ffffff;
}
</style>
