<template>
  <div class="container-login">
    <div class="vpos-container">
      <div class="box-login">
        <div class="box-login-header">
          <img src="../../../assets/images/icons/vPOS-logo.svg" alt="">
        </div>
        <div class="col errors">
            <div class="error-box error-container" :class="{'fade-in': hadError ? true : false }"  role="alert">
              <div class="alert-message" v-if="hadError">{{hadError}}</div>
              <!-- <div class="alert-button" v-if="hadError" @click="closeError">X</div> -->
            </div>
        </div>
        <div class="box-login-body" v-if="!hadSuccess">
          <div >
            <div class="col">
                <div class="form-group" >
                  <div class="col-md-12">
                    <input type="password"
                    :class="{'form-control': true, 'is-input-danger': errors.has('initialForm.password')}"
                    id="User-Password"
                    ref="new"
                    name="initialForm.password"
                    placeholder="Nova Senha"
                    v-model="initialForm.password"
                    v-validate="'required'"
                    data-vv-as="Senha" />
                  </div>
                  <div class="error-box">
                    <span v-show="errors.has('initialForm.password')" class="help is-danger">{{ errors.first('initialForm.password') }}</span>
                  </div>
                </div>
            </div>
            <div class="col" >
                <div class="form-group" >
                  <div class="col-md-12">
                    <input type="password"
                    :class="{'form-control': true, 'is-input-danger': errors.has('initialForm.confirmed_password')}"
                    id="User-Password"
                    name="initialForm.confirmed_password"
                    placeholder="Repetir Nova Senha"
                    v-model="initialForm.confirmed_password"
                    v-validate="'required|confirmed:new|min:6'"
                    @keypress.enter="ProcessForm"
                    data-vv-as="Repetir Nova Senha" />
                  </div>
                  <div class="error-box">
                    <span v-show="errors.has('initialForm.confirmed_password')" class="help is-danger">{{ errors.first('initialForm.confirmed_password') }}</span>
                  </div>
                </div>
            </div>
            <div class="col side-login">
              <button class="vpos-btn success" @click="ProcessForm">
                <span v-if="!isRequesting"> Alterar a Senha</span>
                <div class="loading-dots" v-if="isRequesting">
                  <div class="loading-dots--dot"></div>
                  <div class="loading-dots--dot"></div>
                  <div class="loading-dots--dot"></div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="request-message" v-else>
          <div v-if="hadSuccess">
            <h3>{{ $t('recoveryPassword.alert.changed') }}</h3>
            <img src="../../../assets/images/icons/success.svg" alt="">
            <p>{{ hadSuccess }}</p>
          </div>
          <div v-if="hadError">
            <h3>{{ $t('recoveryPassword.alert.error') }}</h3>
            <img src="../../../assets/images/icons/error.svg" alt="">
            <p>{{ hadError }}</p>
          </div>
        </div>
        <button v-if="hadSuccess || hadError" @click="goBack" class="vpos-btn btn-outline-primary back-btn">
          {{ $t('recoveryPassword.back') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Login',
  props: {
    code: {
      type: String
    },
    username: {
      type: String
    }
  },
  data () {
    return {
      isRequesting: false,
      initialForm: {},
      isSuccess: false,
      form: {},
      hadError: '',
      isVerifyng: false
    }
  },
  computed: {
    ...mapGetters('Admins', ['isLoggedIn'])
  },
  methods: {
    /*
     * ProcessForm: This method will validate the DoLogin() method
     */
    async ProcessForm () {
      const result = await this.$validator.validateAll()
      return result ? this.ChangePassword() : result
    },
    /**
     * UpdateSupplier: This method will send form to serve, for update
     */
    async ChangePassword () {
      this.isRequesting = true
      this.form.code = this.code
      this.form.new_password = this.initialForm.password
      try {
        // eslint-disable-next-line no-const-assign
        await this.axios.put(`/merchants/accounts/${this.username}/passwords`, this.form)
        this.isSuccess = true
        this.hadSuccess = 'Senha Alterada com Sucesso.'
      } catch (e) {
        this.hadError =
          'Não foi possível realizar esta operação. Tente novamente'
      }
      this.isRequesting = false
    },
    /**
     * HangleLoginErrors: It is a helper method that will
     * handle all the errors regarding the Login method.
     */
    HangleLoginErrors (error) {
      // Error messages
      const defaultErrorMessage = `Não foi possível realizar esta operação.`
      const notFoundError = `Conta não encontrada.`
      const wrongPasswordError = `Verifique os seus dados de autenticação.`

      // Check for the error response if there's any
      if (error.response) {
        switch (error.response.status) {
          case 404:
            this.hadError = notFoundError
            break
          case 400:
            this.hadError = wrongPasswordError
            break
          default:
            this.hadError = defaultErrorMessage
        }
      } else {
        this.hadError = defaultErrorMessage
      }
    },
    goBack () {
      this.$router.push({ name: 'Login' })
    }
  },
  created () {
    // Check if the user is logged in, if so, redirect to the
    // admin area: Dashboard.
    // if (this.isLoggedIn) {
    //   this.$router.push({ name: 'Dashboard' })
    // }
  }
}
</script>
<style lang="scss" scoped></style>
