<template>
  <div>
    <div class="main-header">
      <div class="main-header__heading">
        <bread-crumbs></bread-crumbs>
      </div>
        <div class="col col-9 head-info">
          <span>
            <button @click="OpenGenerateToken" class="btn btn-primary"> <i class="fas fa-cog"></i> {{$t('tokens.newBtn')}}</button>
          </span>
        <div class="float-btn plus" @click="NewResourse({ name: 'RegistTokens' })"><i class="fas fa-plus"></i></div>
      </div>
    </div>
    <div class="container">
      <Table
          :cols="cols"
          :data="tokens"
          title="tokens"
          :searchMethod="GetTokens"
          :pagination="pagination"
          :paginationMethod="GetTokens"
          :sortMethod="GetTokens"
          :changePage="ChangePage"
          resource="tokens"
          editRoute="EditTokens"
          :pageCount="pageCount"
          :detailMethod="OpenDeleteToken"
          :hasFilter="false"
        ></Table>
    </div>
    <modal name="generate-token">
        <div class="top-right">
          <i class="fas fa-times" @click="$modal.hide('generate-token')"></i>
        </div>
        <div class="vp-header">
          <span v-if="!tokenCreated">{{$t('tokens.token.titleInitialStatus')}}</span>
          <span v-else>{{$t('tokens.token.titleSuccessStatus')}}</span>
        </div>
        <div class="vp-body ">
          <div class="col" v-if="!tokenCreated">
            <div class="vpos-special-input token-first-input">
              <input type="text" v-model="form.label" :placeholder="$t('tokens.token.description')" >
            </div>
            <div class="vpos-special-input token-first-input">
              <multiselect
                  v-model="environment"
                  :options="environments"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="System Type"
                  label="name"
                  @close="setSystemEnvironment(environment)">
              </multiselect>
            </div>
            <div class="vpos-special-input">
              <vuejs-datepicker
                input-class="form-control"
                :language="ptPT"
                :placeholder="$t('tokens.token.dateExpiryPlaceholder')"
                v-model="form.expiry"
                :disabled-dates="disabledDates.disabledDates"
              ></vuejs-datepicker>
            </div>
          </div>
          <div class="col" v-else>
            <div class="vpos-details">
              <p>{{$t('tokens.token.tokenTitle')}}</p>
              <span>{{ formCreated.token }}</span>
            </div>
            <div class="vpos-details">
                <span class="red-note"><strong>{{$t('tokens.token.note')}}</strong>{{$t('tokens.token.noteText')}}</span>
            </div>
          </div>
        </div>
        <div class="vp-footer" v-if="!tokenCreated">
          <button class="btn btn-success" @click="GenerateToken" :disabled="isRequesting">
            <span v-if="!isRequesting">{{$t('tokens.token.generateBtn')}}</span>
            <div class="loading-dots" v-if="isRequesting">
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
            </div>
          </button>
        </div>
    </modal>
    <modal name="delete-token">
        <div class="top-right">
          <i class="fas fa-times" @click="$modal.hide('delete-token')"></i>
        </div>
        <div class="vp-header">
          <span>{{$t('tokens.modal.question')}}</span>
        </div>
        <div class="vp-footer">
          <button class="btn btn-danger mg-bottom" @click="DeleteToken" :disabled="isRequesting">
            <span v-if="!isRequesting">{{$t('tokens.modal.removeBtn')}}</span>
            <div class="loading-dots" v-if="isRequesting">
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
            </div>
          </button>
          <button class="btn btn-outline-primary" @click="hideDeleteToken" >
            <span>{{$t('tokens.modal.cancelBtn')}}</span>
          </button>
        </div>
    </modal>
    <div>
      <alert :text="$t('alert.success')" v-if="hadSuccess" :message="hadSuccess" type="toast--green"></alert>
      <alert :text="$t('alert.error')" v-if="hadError" :message="hadError" type="toast--red"></alert>
    </div>
  </div>
</template>
<script>
import Table from '@/components/BackOffice/Layouts/Table.vue'
import { pt } from 'vuejs-datepicker/dist/locale'
var state = {
  disabledDates: {
    to: new Date()
  }
}
export default {
  components: {
    Table
  },
  data () {
    return {
      form: {},
      environment: '',
      environments: [
        {
          name: 'Production', id: 'PRODUCTION'
        },
        {
          name: 'Sandbox', id: 'SANDBOX'
        }
      ],
      formCreated: {},
      tokenCreated: false,
      tokens: [],
      ptPT: pt,
      hadSuccess: '',
      hadError: '',
      isRequesting: false,
      deleteID: '',
      transaction: {
        operator: {
          'first_name': '',
          'last_name': ''
        }
      },
      pageCount: 4,
      disabledDates: state,
      x: window.matchMedia('(max-width: 480px)'),
      y: window.matchMedia('(max-width: 1024px)'),
      z: window.matchMedia('(max-width: 1500px)')
    }
  },
  computed: {
    PT () {
      return pt
    },
    cols () {
      if (this.x.matches) { // If media query matches
        return [
          { name: 'label', label: this.$t('tokens.table.labels.label') },
          { name: 'state', label: this.$t('tokens.table.labels.state') },
          { name: 'expiry', label: this.$t('tokens.table.labels.expiry') }
        ]
      } else {
        return [
          { name: 'label', label: this.$t('tokens.table.labels.label') },
          { name: 'environment', label: this.$t('tokens.table.labels.environment') },
          { name: 'state', label: this.$t('tokens.table.labels.state') },
          { name: 'created_date', label: this.$t('tokens.table.labels.createdDate') },
          { name: 'expiry', label: this.$t('tokens.table.labels.expiry') }
        ]
      }
    },
    pagination () {
      if (this.x.matches) { // If media query matches
        return {
          perPage: 7,
          pageable: { pageNumber: 0 },
          isFirstPage: false,
          isLastPage: false
        }
      } else {
        return {
          perPage: 10,
          pageable: { pageNumber: 0 },
          isFirstPage: false,
          isLastPage: false
        }
      }
    }
  },
  methods: {
    ChangePage (page) {
      this.pagination.pageable.pageNumber = page
    },
    setSystemEnvironment (val = null) {
      this.environment = val.id
    },
    /*
     *  GetTokens: This method will fire a GET request
     *  to fetch the tokens and the will store the result
     *  into the orders local state property
     */
    async GetTokens (type, sort = '', search = '', size) {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      var sorters = []
      if (sort) {
        sorters.push(sort)
      }
      if (type === 'next') {
        this.pagination.pageable.pageNumber += 1
      }

      if (type === 'prev') {
        this.pagination.pageable.pageNumber -= 1
      }

      // API query options like: sorts and pagination
      let query = ''
      query += `page=${this.pagination.pageable.pageNumber}`
      query += size ? `&size=${size}` : `&size=${this.pagination.perPage}`
      query += `&sort=createdDate,desc`

      try {
        const result = await this.axios.get(`/merchants/${merchantID}/tokens?${query}`)
        const res = result.data
        const r = res.data
        this.totalElements = r.items_count
        this.tokens = r.map(item => {
          item.state = this.$options.filters.requestStatus(item.state)
          item.created_date = this.$options.filters.isDate(item.created_date)
          item.expiry = this.$options.filters.isDate(item.expiry)
          return item
        })
        this.pagination.isFirstPage = res.is_first_page
        this.pagination.isLastPage = res.is_last_page
        this.pageCount = res.total_pages
      } catch (e) {
        this.hadError = this.$t('tokens.alert.getError')
      }
      this.isRequesting = false
    },
    /**
     * GenerateToken: This method will fire a POST request
     */
    async GenerateToken () {
      this.isRequesting = true
      this.form.environment = this.environment
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      try {
        const result = await this.axios.post(
          `/merchants/${merchantID}/tokens`, this.form
        )
        this.tokenCreated = true
        this.formCreated = result.data
        this.hadSuccess = this.$t('tokens.alert.success')
      } catch (e) {
        this.TokenError(e)
      }
      // this.hideGenerateToken()
      this.isRequesting = false
      this.GetTokens()
      setTimeout(() => {
        this.hadSuccess = ''
        this.hadError = ''
      }, 6000)
    },
    /**
     * DeleteToken: This method will fire a DELETE request
     */
    async DeleteToken () {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      try {
        await this.axios.delete(
          `/merchants/${merchantID}/tokens/${this.deleteID}`
        )
        this.hadSuccess = this.$t('tokens.alert.removeSuccess')
        this.GetTokens()
      } catch (e) {
        this.TokenError(e)
      }
      this.hideDeleteToken()
      this.isRequesting = false
      setTimeout(() => {
        this.hadSuccess = ''
        this.hadError = ''
      }, 6000)
    },
    /**
     * TokenError: This method will handle all the errors related
     * to the generate token process.
     *
     * @param {object} error
     * */
    TokenError (error) {
      const defaultErrorMessage = this.$t('tokens.alert.error')
      if (error.response) {
        switch (error.response.data[0].code) {
          case 'MERCHANT_ID_DOES_NOT_MATCH_AUTHENTICATED_ACCOUNT':
            this.hadError = this.$t('tokens.alert.merchantDoesntMatch')
            break
          case 'MERCHANT_WITHOUT_ACTIVE_SUBSCRIPTION':
            this.hadError = this.$t('tokens.alert.merchantWithoutActive')
            break
          case 'TOKEN_IS_IN_REQUESTED_STATE':
            this.hadError = this.$t('tokens.alert.tokenIsInRequested')
            break
          case 'TOKEN_NOT_FOUND':
            this.hadError = this.$t('tokens.alert.tokenNotFound')
            break
          case 'MERCHANT_NOT_SYNCHRONIZED_WITH_EMIS':
            this.hadError = this.$t('tokens.alert.merchantNotSync')
            break
          default:
            this.hadError = defaultErrorMessage
            break
        }
      } else {
        this.hadError = defaultErrorMessage
      }
    },
    OpenGenerateToken () {
      this.$modal.show('generate-token')
    },
    hideGenerateToken () {
      this.$modal.hide('generate-token')
      this.tokenCreated = false
    },
    OpenDeleteToken (id) {
      this.$modal.show('delete-token')
      this.deleteID = id
    },
    hideDeleteToken () {
      this.$modal.hide('delete-token')
      this.tokenCreated = false
    }
  },
  created () {
    this.userName = localStorage.getItem('vpos_body_name') ? localStorage.getItem('vpos_body_name') : 'Kevin Durant'
    this.GetTokens()
  }
}
</script>
<style style="scss" scoped>
.mg-bottom{
  margin-bottom: 20px;
}

.vdp-datepicker{
  width: 100%;
}

.token-first-input{
  margin-bottom: 20px;
}

.red-note{
  color: red;
  font-size: 14px;
  text-align: center;
}
</style>
