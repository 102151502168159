<template>
  <div>
    <div class="main-header">
      <div class="main-header__heading">
        <bread-crumbs></bread-crumbs>
        <!-- <span>Dashboard</span> -->
      </div>
      <div class="col col-9 head-info"></div>
    </div>
    <div class="main-overview">
      <router-link :to="{name: 'ListSubscriptions'}" tag="div" class="overviewcard">
        <div class="overviewcard__status overviewcard__status__sucess"></div>
        <div class="overviewcard__describe">
          <div class="overviewcard__icon">{{$t('dashboard.cards.subscriptions')}}</div>
          <div class="overviewcard__info">{{subscriptionsCount}}</div>
        </div>
      </router-link>
      <router-link :to="{name: 'ListOperators'}" tag="div" class="overviewcard">
        <div class="overviewcard__status overviewcard__status__danger"></div>
        <div class="overviewcard__describe">
          <div class="overviewcard__icon">{{$t('dashboard.cards.operators')}}</div>
          <div class="overviewcard__info">{{operatorsCount}}</div>
        </div>
      </router-link>
      <router-link :to="{name: 'ListTransitions', params:{type: 'PAYMENT'}}" tag="div" class="overviewcard">
        <div class="overviewcard__status overviewcard__status__primary"></div>
        <div class="overviewcard__describe">
          <div class="overviewcard__icon">{{$t('dashboard.cards.payments')}}</div>
          <div class="overviewcard__info">{{transactionsPaymentCount}}</div>
        </div>
      </router-link>
      <router-link :to="{name: 'ListTransitions', params:{type: 'REFUND'}}" tag="div" class="overviewcard">
        <div class="overviewcard__status overviewcard__status__warning"></div>
        <div class="overviewcard__describe">
          <div class="overviewcard__icon">{{$t('dashboard.cards.refunds')}}</div>
          <div class="overviewcard__info">{{transactionsRefundCount}}</div>
        </div>
      </router-link>
    </div>
    <div class="main-cards">
      <div class="dashboard-card">
        <div class="chart-wrapper">
          <div class="rotate-text"><span>{{$t('dashboard.charts.value')}}</span></div>
          <v-chart v-if="options" :options="options" :autoresize="true"></v-chart>
        </div>
        <div class="chart-footer">
          <div class="chart-description"><span>{{monthText}} {{$t('dashboard.charts.of')}} {{yearText}}</span></div>
          <div class="chart-legends">
            <div class="card-legends"><span>{{$t('dashboard.charts.payments')}}</span> <div class="recepts"></div></div>
            <div class="card-legends"><span>{{$t('dashboard.charts.refunds')}}</span> <div class="devolutions"></div></div>
          </div>
        </div>
      </div>
      <div class="dashboard-card-info">
        <div class="card-info-header">
          <span>{{$t('dashboard.lastTransactions.title')}}</span>
        </div>
        <div class="card-info-body">
          <div class="info-body-card" v-for="(trans, index) in lateralTransactions.slice(0,20)" :key="index" @click="openDetail(trans.id)">
            <div class="val-details"> <span>{{ trans.customer }}</span> <span class="card-status"
            :class="{'card-success': trans.status === 'filters.transactionsStatus.accepted',
            'card-warning': trans.status === 'filters.transactionsStatus.new',
            'card-primary': trans.status === 'filters.transactionsStatus.initial',
            'card-danger': trans.status === 'filters.transactionsStatus.rejected'}"
            >{{ $t(trans.status) }}</span></div>
            <div class="val-info">
              <span>{{ trans.created_date }}
                <span style="margin-left: 12px;">
                  <span class="arrow-type" style="color: #75ac0b;" v-if="isToken= trans.type === 'Pagamento'"><i class="fas fa-arrow-up"></i></span>
                  <span class="arrow-type" style="color: #fd6161; width: 100%;" v-else-if="isToken= trans.type === 'Estorno'"><i class="fas fa-arrow-down"></i></span>
                </span>
              </span>
              <span>
                {{trans.amount}}
              </span>
            </div>
          </div>
        </div>
        <div class="card-info-footer" v-if="lateralTransactions.length > 5">
          <router-link :to="{name: 'ListTransitions', params: {type: 't'}}" tag="span">{{$t('dashboard.lastTransactions.more')}}</router-link>
        </div>
      </div>
    </div>
     <modal name="get-transition">
       <div class="top-right">
          <i class="fas fa-times" @click="$modal.hide('get-transition')"></i>
        </div>
        <div class="vp-body vpos-details-modal">
          <div class="vpos-details">
            <p>{{ $t('dashboard.modal.type') }}</p>
            <span>{{ $t(transaction.type) }}</span>
          </div>
          <div class="vpos-details">
            <span class="money">{{transaction.amount}}</span>
          </div>
          <div class="vpos-details">
            <p>{{ $t('dashboard.modal.customer') }}</p>
            <span>{{ transaction.customer }}</span>
          </div>
          <div class="vpos-details">
            <p>{{ $t('dashboard.modal.provider') }}</p>
            <div class="img-detail">
              <img v-if="!transaction.provider" src="../../../assets/images/No_image_available.png" />
              <img v-else src="../../../assets/images/logo/Logo_MCX_Express_Provider_White.png" />
            </div>
          </div>
          <div class="vpos-details">
            <p>{{ $t('dashboard.modal.operatorName') }}</p>
            <span >{{ operatorFirstName }} {{ operatorLastName }}</span>
          </div>
          <div class="vpos-details">
            <div class="vpos-details-status radius"
            :class="{'is-success-box': 'filters.transactionsStatus.accepted' === transaction.status,
              'is-warning-box': 'filters.transactionsStatus.new' === transaction.status,
              'is-primary-box': 'filters.transactionsStatus.initial' === transaction.status,
              'is-danger-box': 'filters.transactionsStatus.rejected' === transaction.status}">
              <i v-for="(trans, index) of transactionStatus" :key="index" v-show="trans.status === transaction.status" :class="trans.class"></i>
            </div>
          </div>
        </div>
    </modal>
  </div>
</template>
<script>
import moment from 'moment'
// import { mapState } from 'vuex'

export default {
  components: {},
  data () {
    return {
      isRequesting: false,
      hadError: '',
      hadSuccess: '',
      // monthText: '',
      yearText: '',
      isRendeering: false,
      operatorsCount: 0,
      subscriptionsCount: 0,
      transactionsPaymentCount: 0,
      transactionsRefundCount: 0,
      transactionStatus: [
        { status: 'filters.transactionsStatus.accepted', class: 'fas fa-check' },
        { status: 'filters.transactionsStatus.rejected', class: 'fas fa-times' },
        { status: 'filters.transactionsStatus.new', class: 'far fa-clock' },
        { status: 'filters.transactionsStatus.initial', class: 'fas fa-exclamation' }
      ],
      lateralTransactions: [],
      transaction: {
        type: '',
        amount: '',
        customer: '',
        provider: '',
        operator: {
          first_name: 'unknow',
          last_name: ''
        }
      },
      operatorFirstName: 'unknow',
      operatorLastName: 'unknow',
      firstWeekRefunds: 0,
      firstWeekPayments: 0,
      secondWeekRefunds: 0,
      secondWeekPayments: 0,
      thirdWeekRefunds: 0,
      thirdWeekPayments: 0,
      fourthWeekRefunds: 0,
      fourthWeekPayments: 0,
      firstWeek: [],
      secondWeek: [],
      thirdWeek: [],
      fourthWeek: [],
      totalWeeks: [],
      x: window.matchMedia('(max-width: 480px)'),
      y: window.matchMedia('(max-width: 1024px)'),
      z: window.matchMedia('(max-width: 1500px)')
    }
  },
  computed: {
    // ...mapState(['Responsive', 'x','y','z']),
    options () {
      return {
        title: {
          text: this.$t('dashboard.charts.title'),
          x: 'center',
          textStyle: {
            fontSize: 24
          }
        },
        tooltip: {},
        xAxis: {
          data: this.weekly
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Recebimento',
            type: 'line',
            data: [this.firstWeekPayments, this.secondWeekPayments, this.thirdWeekPayments, this.fourthWeekPayments]
          },
          {
            name: 'Estorno',
            type: 'line',
            data: [this.firstWeekRefunds, this.secondWeekRefunds, this.thirdWeekRefunds, this.fourthWeekRefunds]
          }
        ],
        color: ['#1d72f3', '#ffc007'],
        animationDuration: 2000
      }
    },
    monthText () {
      var d = new Date()
      var m = d.getMonth()

      const numString = m.toString()
      var value = parseInt(numString) + 1

      return this.$t(`months.` + value)
    },
    weekDaysCount () {
      return [
        this.$t('mobileWeeks.first'),
        this.$t('mobileWeeks.second'),
        this.$t('mobileWeeks.third'),
        this.$t('mobileWeeks.fourth')
      ]
    },
    weeksCounts () {
      return [
        this.$t('weeksCounts.first'),
        this.$t('weeksCounts.second'),
        this.$t('weeksCounts.third'),
        this.$t('weeksCounts.fourth')
      ]
    },
    weekly () {
      if (this.x.matches) { // If media query matches
        return this.weekDaysCount
      } else if (this.y.matches) {
        return this.weeksCounts
      } else if (this.z.matches) {
        return this.weeksCounts
      } else {
        return this.weeksCounts
      }
    }
  },
  methods: {
    getRandomInt () {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    /**
     * GetSubscription: This method will fire a GET request and then
     * assign the response data into the state property: form
     */
    async GetSubscriptions () {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      const newSub = []
      try {
        const result = await this.axios.get(
          `/merchants/${merchantID}/subscriptions`
        )
        const res = result.data
        res.data.map(item => {
          if (item.state === 'ACTIVE') {
            newSub.push(item)
            return item
          }
        })
        this.subscriptionsCount = newSub.length
      } catch (e) {
        this.hadError = 'Não foi possível carregar as informações.'
      }
      this.isRequesting = false
    },
    /**
     * GetOperators: This method will fire a GET request and then
     * assign the response data into the state property: form
     */
    async GetOperators () {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      try {
        const result = await this.axios.get(
          `/merchants/${merchantID}/operators`
        )
        const res = result.data
        this.operatorsCount = res.data.length
      } catch (e) {
        this.hadError = 'Não foi possível carregar as informações.'
      }
      this.isRequesting = false
    },
    /**
     * GetOperators: This method will fire a GET request and then
     * assign the response data into the state property: form
     */
    async GetCountTransactions () {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      var date = new Date()
      var y = date.getFullYear()
      var m = date.getMonth()
      var sinceDate = new Date(y, m, 1, 4)
      var untilDate = new Date(y, m + 1, 0, 4)
      let query = ''
      query += `size=${9999999}`
      query += `&created_since=${sinceDate.toISOString()}`
      query += `&created_until=${untilDate.toISOString()}`
      try {
        const result = await this.axios.get(
          `/merchants/${merchantID}/transactions?${query}`
        )
        const res = result.data
        res.data.map(item => {
          if (item.type === 'PAYMENT' && item.status === 'ACCEPTED') {
            this.transactionsPaymentCount = this.transactionsPaymentCount + item.amount
          } else if (item.type === 'REFUND' && item.status === 'ACCEPTED') {
            this.transactionsRefundCount = this.transactionsRefundCount + item.amount
          }
        })
        this.transactionsPaymentCount = this.$options.filters.toKwanza(this.transactionsPaymentCount)
        this.transactionsPaymentCount = this.$options.filters.replaceComa(this.transactionsPaymentCount)
        this.transactionsRefundCount = this.$options.filters.toKwanza(this.transactionsRefundCount)
        this.transactionsRefundCount = this.$options.filters.replaceComa(this.transactionsRefundCount)
      } catch (e) {
        this.hadError = 'Não foi possível carregar as informações.'
      }
      this.isRequesting = false
    },
    /**
     * GetLateralTransactions: This method will fire a GET request and then
     * assign the response data into the state property: form
     */
    async GetLateralTransactions () {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')

      let query = ''
      query += `sort=createdDate,desc`

      try {
        const result = await this.axios.get(
          `/merchants/${merchantID}/transactions?${query}`
        )
        const res = result.data
        this.lateralTransactions = res.data.map(item => {
          item.amount = this.$options.filters.toKwanza(item.amount)
          item.customer = this.$options.filters.formatCellphone(5, 4, '****', item.customer)
          item.amount = this.$options.filters.replaceComa(item.amount)
          item.type = this.$options.filters.transactionsType(item.type)
          item.status = this.$options.filters.transactionsStatus(item.status)
          item.created_date = this.$options.filters.isDate(item.created_date)
          return item
        })
      } catch (e) {
        this.hadError = 'Não foi possível carregar as informações.'
      }
      this.isRequesting = false
    },
    /**
     * GetTransaction: This method will fire a GET request and then
     * assign the response data into the state property: form
     */
    async GetTransaction (id) {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      try {
        const result = await this.axios.get(
          `/merchants/${merchantID}/transactions/${id}`
        )
        this.transaction = result.data
        this.transaction.status = this.$options.filters.transactionsStatus(this.transaction.status)
        this.transaction.type = this.$options.filters.transactionsType(this.transaction.type)
        this.transaction.provider = this.$options.filters.providerType(this.transaction.provider)
        this.transaction.amount = this.$options.filters.toKwanza(this.transaction.amount)
        this.transaction.amount = this.$options.filters.replaceComa(this.transaction.amount)
        this.operatorFirstName = this.transaction.operator['first_name']
        this.operatorLastName = this.transaction.operator['last_name']
      } catch (e) {
        this.hadError = 'Não foi possível carregar as informações.'
      }
      this.isRequesting = false
    },
    showTransaction () {
      this.$modal.show('get-transition')
    },
    openDetail (id) {
      this.setTransaction()
      this.GetTransaction(id)
      this.showTransaction()
    },
    weekDate (value) {
      const entireWeek = moment(value).format('MMMM D -') + moment(value).add(7, 'days').format('D YYYY')
      return entireWeek // July 6 - 13 2020
    },
    getFirstWeek () {
      var date = new Date()
      var y = date.getFullYear()
      var m = date.getMonth()
      var firstDay = new Date(y, m, 1, 4)
      var lastDay = new Date(y, m + 1, 0, 4)
      var i = 1
      while (firstDay.getTime() < lastDay.getTime() && i <= 7) {
        this.firstWeek.push(new Date(y, m, i, 4))
        i++
      }
    },
    getSecondWeek () {
      var date = new Date()
      var y = date.getFullYear()
      var m = date.getMonth()
      var firstDay = new Date(y, m, 1, 4)
      var lastDay = new Date(y, m + 1, 0, 4)
      var i = 8
      while (firstDay.getTime() < lastDay.getTime() && i <= 14) {
        this.secondWeek.push(new Date(y, m, i, 4))
        i++
      }
    },
    getThirdWeek () {
      var date = new Date()
      var y = date.getFullYear()
      var m = date.getMonth()
      var firstDay = new Date(y, m, 1, 4)
      var lastDay = new Date(y, m + 1, 0, 4)
      var i = 15
      while (firstDay.getTime() < lastDay.getTime() && i <= 21) {
        this.thirdWeek.push(new Date(y, m, i, 4))
        i++
      }
    },
    getFourthWeek () {
      var date = new Date()
      var y = date.getFullYear()
      var m = date.getMonth()
      var lastDay = new Date(y, m + 1, 0, 4)
      var i = 22
      while (i <= lastDay.getDate()) {
        this.fourthWeek.push(new Date(y, m, i, 4))
        i++
      }
    },
    /*
     *  GetFirstWeekTransactions: This method will fire a GET request
     *  to fetch the subscriptions and the will store the result
     *  into the orders local state property
     */
    async GetFirstWeekTransactions (sinceDate = null, untilDate = null) {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')

      // API query options like: sorts and pagination
      let query = ''
      query += `size=${9999999}`
      query += `&created_since=${sinceDate.toISOString()}`
      query += `&created_until=${untilDate.toISOString()}`

      try {
        const result = await this.axios.get(`/merchants/${merchantID}/transactions?${query}`)
        const res = result.data
        const r = res.data
        this.transaction = r.map(item => {
          if (item.type === 'PAYMENT' && item.status === 'ACCEPTED') {
            this.firstWeekPayments = this.firstWeekPayments + item.amount++
          } else if (item.type === 'REFUND' && item.status === 'ACCEPTED') {
            this.firstWeekRefunds = this.firstWeekRefunds + item.amount
          }
          return item
        })
      } catch (e) {
        this.hadError =
        'Não foi possível carregar as transacções. Actualize a página.'
      }
      this.isRequesting = false
    },
    /*
     *  GetSecondWeekTransactions: This method will fire a GET request
     *  to fetch the subscriptions and the will store the result
     *  into the orders local state property
     */
    async GetSecondWeekTransactions (sinceDate = null, untilDate = null) {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')

      // API query options like: sorts and pagination
      let query = ''
      query += `size=${9999999}`
      query += `&created_since=${sinceDate.toISOString()}`
      query += `&created_until=${untilDate.toISOString()}`

      try {
        const result = await this.axios.get(`/merchants/${merchantID}/transactions?${query}`)
        const res = result.data
        const r = res.data
        this.transaction = r.map(item => {
          if (item.type === 'PAYMENT' && item.status === 'ACCEPTED') {
            this.secondWeekPayments = this.secondWeekPayments + item.amount++
          } else if (item.type === 'REFUND' && item.status === 'ACCEPTED') {
            this.secondWeekRefunds = this.secondWeekRefunds + item.amount
          }
          return item
        })
      } catch (e) {
        this.hadError =
        'Não foi possível carregar as transacções. Actualize a página.'
      }
      this.isRequesting = false
    },
    /*
     *  GetThirdWeekTransactions: This method will fire a GET request
     *  to fetch the subscriptions and the will store the result
     *  into the orders local state property
     */
    async GetThirdWeekTransactions (sinceDate = null, untilDate = null) {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')

      // API query options like: sorts and pagination
      let query = ''
      query += `size=${9999999}`
      query += `&created_since=${sinceDate.toISOString()}`
      query += `&created_until=${untilDate.toISOString()}`

      try {
        const result = await this.axios.get(`/merchants/${merchantID}/transactions?${query}`)
        const res = result.data
        const r = res.data
        this.transaction = r.map(item => {
          if (item.type === 'PAYMENT' && item.status === 'ACCEPTED') {
            this.thirdWeekPayments = this.thirdWeekPayments + item.amount++
          } else if (item.type === 'REFUND' && item.status === 'ACCEPTED') {
            this.thirdWeekRefunds = this.thirdWeekRefunds + item.amount
          }
          return item
        })
      } catch (e) {
        this.hadError =
        'Não foi possível carregar as transacções. Actualize a página.'
      }
      this.isRequesting = false
    },
    /*
     *  to fetch the subscriptions and the will store the result
     *  into the orders local state property
     */
    async GetFourthWeekTransactions (sinceDate = null, untilDate = null) {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')

      // API query options like: sorts and pagination
      let query = ''
      query += `size=${9999999}`
      query += `&created_since=${sinceDate.toISOString()}`
      query += `&created_until=${untilDate.toISOString()}`

      try {
        const result = await this.axios.get(`/merchants/${merchantID}/transactions?${query}`)
        const res = result.data
        const r = res.data
        this.transaction = r.map(item => {
          if (item.type === 'PAYMENT' && item.status === 'ACCEPTED') {
            this.fourthWeekPayments = this.fourthWeekPayments + item.amount++
          } else if (item.type === 'REFUND' && item.status === 'ACCEPTED') {
            this.fourthWeekRefunds = this.fourthWeekRefunds + item.amount
          }
          return item
        })
      } catch (e) {
        this.hadError =
        'Não foi possível carregar as transacções. Actualize a página.'
      }
      this.isRequesting = false
    },
    setTransaction () {
      this.transaction = {}
    },
    myFunction (x) {
      if (this.x.matches) { // If media query matches
        this.weekly = this.weekDaysCount
      } else if (this.y.matches) {
        this.weekly = this.weeksCounts
      } else if (this.z.matches) {
        this.weekly = this.weeksCounts
      } else {
        this.weekly = this.weeksCounts
      }
    }
  },
  mounted () {
    this.myFunction(this.x) // Call listener function at run time
    this.myFunction(this.y) // Call listener function at run time
    this.myFunction(this.z) // Call listener function at run time
    this.x.addListener(this.myFunction) // Attach listener function on state changes
    this.y.addListener(this.myFunction) // Attach listener function on state changes
    this.z.addListener(this.myFunction) // Attach listener function on state changes
  },
  created () {
    this.userName = localStorage.getItem('vpos_body_name') ? localStorage.getItem('vpos_body_name') : 'Kevin Durant'
    this.GetSubscriptions()
    this.GetOperators()
    this.GetLateralTransactions()
    this.GetCountTransactions()

    var d = new Date()
    // var m = d.getMonth()
    var y = d.getFullYear()

    // this.monthText = this.$t('months.' + 1)
    this.yearText = y

    // Settings Datas per Week
    this.getFirstWeek()
    this.getSecondWeek()
    this.getThirdWeek()
    this.getFourthWeek()

    setTimeout(() => {
      this.GetFirstWeekTransactions(new Date(this.firstWeek[0]), new Date(this.firstWeek[6]))
    }, 100)

    setTimeout(() => {
      this.GetSecondWeekTransactions(new Date(this.secondWeek[0]), new Date(this.secondWeek[6]))
    }, 200)

    setTimeout(() => {
      this.GetThirdWeekTransactions(new Date(this.thirdWeek[0]), new Date(this.thirdWeek[6]))
    }, 300)

    setTimeout(() => {
      this.GetFourthWeekTransactions(new Date(this.fourthWeek[0]), new Date(this.fourthWeek[this.fourthWeek.length - 1]))
    }, 400)
  }
}
</script>
<style style="scss" scoped>
.main-overview {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(265px, 1fr)
  ); /* Where the magic happens */
  grid-auto-rows: 94px;
  grid-gap: 20px;
  margin: 20px;
}

.overviewcard {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);
  cursor: pointer;
}

.overviewcard__icon {
  display: flex;
  text-align: center;
}

.overviewcard__describe {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  padding: 20px;
  flex-grow: 1;
}

.overviewcard__status {
  content: "";
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.overviewcard__status__sucess {
  background-color: #2bde3f;
}

.overviewcard__status__danger {
  background-color: #ff5733;
}

.overviewcard__status__primary {
  background-color: #1d72f3;
}

.overviewcard__status__warning {
  background-color: #ffc007;
}

.overviewcard__icon {
  font-family: "Open Sans Regular";
  font-size: 14;
  color: slategray;
}

.overviewcard__info {
  font-family: "Open Sans SemiBold";
  font-size: 20px;
  color: slategray;
  display: flex;
  justify-content: flex-end;
}

.chart__label{
  color: #27A7BE;
  font-size: 25px;
}

.chart-row .chart-col:first-child{
  width: 70%;
}

.chart-row .chart-col:last-child{
  width: 28%;
}

.dashboard-card-info {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 0 20px;
}

@media screen and (max-width: 767px){
  .dashboard-card,
  .main-header{
    display: none;
  }
}

@media screen and (max-width: 1024px){
  .dashboard-card{
    margin-bottom: 20px;
  }

  .dashboard-card-info {
    width: 100%;
    margin: 0;
  }
}

.card-info-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.card-info-header span {
  color: #555555;
  font-family: "Open Sans SemiBold";
  font-size: 13px;
}

.card-info-body {
  margin: 0;
  margin-top: 20px;
  height: 380px;
  overflow-y: auto;
}

@media screen and (max-width: 424px){
  .card-info-body {
    grid-template-columns: repeat(auto-fit, minmax(215px, 1fr));
  }
}

.info-body-card {
  height: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #999;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
  cursor: pointer;
}

.info-body-card div{
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  color: #555555;
  margin-bottom: 2px;
}

.val-info, .val-details {
  display: flex;
  justify-content: space-between;
}

.val-info span{
  font-family: 'Open Sans Regular';
  font-size: 13px;
  color: #555555;
  height: 15px;
}

.val-details .card-status {
  border-radius: 5px;
  padding: 1px 0;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #fff;
}

.card-status.card-success{
  background-color: #2bde3f;
}

.card-status.card-primary{
  background-color: #1d72f3;
}

.card-status.card-danger{
  background-color: #ff5733;
}

.card-status.card-warning{
  background-color: #ffc007;
}

.card-info-footer span{
  font-family: 'Open Sans SemiBold';
  color: #1d72f3;
  cursor: pointer;
}

.card-legends{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  /* align-items: flex-end; */
}

.card-legends div{
  width: 50px;
  height: 15px;
  border-radius: 4px;
  margin-left: 20px;
}

.card-legends span{
  font-family: 'Open Sans Regular';
  font-size: 12px;
  color: #555555;
}

.card-legends .recepts{
  background-color: #1d72f3;
}

.card-legends .devolutions{
  background-color: #ffc007;
}

.chart-footer{
  width: 100%;
  display: flex;
}

.chart-description{
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  font-family: 'Open Sans Regular';
  font-size: 14px;
}

.chart-description span{
  font-family: 'Open Sans SemiBold';
  margin-right: 5px;
}

@media screen and (max-width: 424px){
  .chart-description{
    font-size: 10px;
    flex-direction: column;
  }
  .chart-description span{
    font-family: 'Open Sans SemiBold';
    margin-right: 5px;
  }
}
</style>
