var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"form-container"},[_c('div',{staticClass:"detail-box"},[_vm._m(1),_c('div',{staticClass:"detail-info"},[_vm._m(2),_c('div',{staticClass:"status",class:{'is-success-box': 'ACTIVE' === _vm.form.state ,
                     'is-secondary-box': 'PROCESSING' === _vm.form.state,
                     'is-primary-box': 'REQUESTED' === _vm.form.state,
                     'is-danger-box': 'SUSPENDED' === _vm.form.state}},[_vm._v("Pedindo")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-header"},[_c('div',{staticClass:"main-header__heading"},[_vm._v("Detalhe da Subscrição")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-detail"},[_c('img',{attrs:{"src":require("../../../assets/images/No_image_available.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"title"},[_vm._v(" Banco Angolano de Investimento "),_c('span',{staticClass:"subtitle"},[_vm._v("BAI")])])
}]

export { render, staticRenderFns }