import AuthBaseView from '@/views/BackOffice/Auth/AuthBaseView'
import AdminBaseView from '@/views/BackOffice/AdminBaseView'
import View from '@/views/BackOffice/View'

// Auth
import Login from '@/views/BackOffice/Auth/Login'
import AdminResetPassword from '@/views/BackOffice/Auth/AdminResetPassword'
import FinishResetPassword from '@/views/BackOffice/Auth/FinishResetPassword'

// Dashboard
import Dashboard from '@/views/BackOffice/Dashboard/Dashboard'

// Devolution
import RegistDevolutions from '@/views/BackOffice/Devolutions/RegistDevolutions'
import ListDevolutions from '@/views/BackOffice/Devolutions/ListDevolutions'
import EditDevolutions from '@/views/BackOffice/Devolutions/EditDevolutions'

// Operators
import RegistOperators from '@/views/BackOffice/Operators/RegistOperators'
import ListOperators from '@/views/BackOffice/Operators/ListOperators'
import RefundCodes from '@/views/BackOffice/Operators/Refund/RefundCodes'

// Transitions
import RegistTransitions from '@/views/BackOffice/Transitions/RegistTransitions'
import ListTransitions from '@/views/BackOffice/Transitions/ListTransitions'
import EditTransitions from '@/views/BackOffice/Transitions/EditTransitions'

// Subscriptions
import RegistSubscriptions from '@/views/BackOffice/Subscriptions/RegistSubscriptions'
import ListSubscriptions from '@/views/BackOffice/Subscriptions/ListSubscriptions'
import EditSubscriptions from '@/views/BackOffice/Subscriptions/EditSubscriptions'

// Banks
import RegistBanks from '@/views/BackOffice/Banks/RegistBanks'
import ListBanks from '@/views/BackOffice/Banks/ListBanks'
import EditBanks from '@/views/BackOffice/Banks/EditBanks'

// Tokens
import RegistTokens from '@/views/BackOffice/Tokens/RegistTokens'
import ListTokens from '@/views/BackOffice/Tokens/ListTokens'
import EditTokens from '@/views/BackOffice/Tokens/EditTokens'

// Supervisors
import ListSupervisors from '@/views/BackOffice/Supervisors/ListSupervisors'
import RegistSupervisors from '@/views/BackOffice/Supervisors/RegistSupervisors'

// AlterPassword
import AlterPassword from '@/views/BackOffice/AlterPassword/AlterPassword'

// Ediit Profile
import EditProfile from '@/views/BackOffice/EditProfile/EditProfile'

// Auth routes
const Auth = [
  {
    path: '/',
    component: AuthBaseView,
    redirect: Login,
    children: [{
      path: '/',
      name: 'Login',
      component: Login,
      meta: {
        title: 'Login'
      }
    },
    {
      path: 'forget-password',
      name: 'AdminResetPassword',
      component: AdminResetPassword
    },
    {
      path: 'reset-password/:username/:code',
      name: 'FinishResetPassword',
      component: FinishResetPassword,
      props: true
    }
    ]
  }
]

const Banks = [{
  path: '/banks',
  component: View,
  children: [{
    path: '/',
    name: 'ListBanks',
    component: ListBanks,
    meta: {
      title: 'Listar Bancos'
    }
  },
  {
    path: 'regist',
    name: 'RegistBanks',
    component: RegistBanks,
    meta: {
      title: 'Registar Banco'
    }
  },
  {
    path: 'edit/:id',
    name: 'EditBanks',
    component: EditBanks,
    meta: {
      title: 'Editar Banco'
    },
    props: true
  }
  ]
}]

const Devolutions = [{
  path: '/devolutions',
  component: View,
  children: [{
    path: '/',
    name: 'ListDevolutions',
    component: ListDevolutions,
    meta: {
      title: 'Listar Devoluções'
    }
  },
  {
    path: 'regist',
    name: 'RegistDevolutions',
    component: RegistDevolutions,
    meta: {
      title: 'Registar Estorno'
    }
  },
  {
    path: 'edit/:id',
    name: 'EditDevolutions',
    component: EditDevolutions,
    meta: {
      title: 'Editar Estorno'
    },
    props: true
  }
  ]
}]

const Operators = [{
  path: '/operators',
  component: View,
  children: [{
    path: '/',
    name: 'ListOperators',
    component: ListOperators,
    meta: {
      title: 'operators.route.listTitle'
    }
  },
  {
    path: 'details/:id',
    name: 'RefundCodes',
    component: RefundCodes,
    meta: {
      title: 'operators.route.listTitle'
    },
    props: true
  },
  {
    path: 'regist',
    name: 'RegistOperators',
    component: RegistOperators,
    meta: {
      title: 'operators.route.listTitle'
    }
  }
  ]
}]

const Transitions = [{
  path: '/transitions/:type',
  name: 'ListTransitions',
  component: View,
  props: true,
  children: [{
    path: 'list',
    name: 'ListTransitions',
    component: ListTransitions,
    meta: {
      title: 'transactions.route.listTitle'
    },
    props: true
  },
  {
    path: 'regist',
    name: 'RegistTransitions',
    component: RegistTransitions,
    meta: {
      title: 'transactions.route.registTitle'
    }
  },
  {
    path: 'edit/:id',
    name: 'EditTransitions',
    component: EditTransitions,
    meta: {
      title: 'transactions.route.editTitle'
    },
    props: true
  }
  ]
}]

const Subscriptions = [{
  path: '/subscriptions',
  component: View,
  children: [{
    path: '/',
    name: 'ListSubscriptions',
    component: ListSubscriptions,
    meta: {
      title: 'subscriptions.route.listTitle'
    }
  },
  {
    path: 'regist',
    name: 'RegistSubscriptions',
    component: RegistSubscriptions,
    meta: {
      title: 'subscriptions.route.registTitle'
    }
  },
  {
    path: 'edit/:id',
    name: 'EditSubscriptions',
    component: EditSubscriptions,
    meta: {
      title: 'subscriptions.route.editTitle'
    },
    props: true
  }
  ]
}]

const Password = [{
  path: '/alter-password',
  component: View,
  children: [{
    path: '/',
    name: 'AlterPassword',
    component: AlterPassword,
    meta: {
      title: 'changePassword.route.title'
    }
  }]
}]

const Profile = [{
  path: '/edit-profile',
  component: View,
  children: [{
    path: '/',
    name: 'EditProfile',
    component: EditProfile,
    meta: {
      title: 'editProfile.route.title'
    }
  }]
}]

const Tokens = [{
  path: '/tokens',
  component: View,
  children: [{
    path: '/',
    name: 'ListTokens',
    component: ListTokens,
    meta: {
      title: 'tokens.route.listTitle'
    }
  },
  {
    path: 'regist',
    name: 'RegistTokens',
    component: RegistTokens,
    meta: {
      title: 'Registar Token'
    }
  },
  {
    path: 'edit/:id',
    name: 'EditTokens',
    component: EditTokens,
    meta: {
      title: 'Editar Token'
    },
    props: true
  }
  ]
}]

const Supervisors = [{
  path: '/supervisors',
  component: View,
  children: [{
    path: '/',
    name: 'ListSupervisors',
    component: ListSupervisors,
    meta: {
      title: 'Código de Supervisores'
    }
  },
  {
    path: 'regist',
    name: 'RegistSupervisors',
    component: RegistSupervisors,
    meta: {
      title: 'Gerar Código de Supervisores'
    }
  }
  ]
}]

// Admin routes
const Admin = [{
  path: '/m',
  component: AdminBaseView,
  redirect: Dashboard,
  meta: {
    title: 'dashboard.route.title',
    name: 'Dashboard'
  },
  children: [{
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  ...Banks,
  ...Devolutions,
  ...Operators,
  ...Transitions,
  ...Subscriptions,
  ...Password,
  ...Profile,
  ...Tokens,
  ...Supervisors
  ]
}]

// Home page
export default [...Auth, ...Admin]
