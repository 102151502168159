<template>
  <div>
    <div class="main-header">
      <div class="main-header__heading">
        <bread-crumbs></bread-crumbs>
      </div>
        <div class="col col-9 head-info">
          <span>
            <button @click="OpenGenerateSupervisorCode" class="btn btn-primary"> <i class="fas fa-cog"></i> Novo Código</button>
          </span>
        <div class="float-btn plus" @click="NewResourse({ name: 'RegistSupervisors' })"><i class="fas fa-plus"></i></div>
      </div>
    </div>
    <div class="container">
      <Table
          :cols="cols"
          :data="supervisors"
          title="Supervisors"
          :searchMethod="GetSupervisors"
          :pagination="pagination"
          :paginationMethod="GetSupervisors"
          :sortMethod="GetSupervisors"
          :changePage="ChangePage"
          resource="supervisors"
          editRoute="EditSupervisors"
          :pageCount="pageCount"
          :detailMethod="OpenDeleteSupervisorCode"
        ></Table>
    </div>
    <modal name="generate-Supervisor">
        <div class="vp-header">
          <span >Gerar Código de Supervisor</span>
        </div>
        <div class="vp-body ">
        <div class="col" >
            <div class="vpos-special-input Supervisor-first-input">
              <multiselect
                v-model="form.operator"
                :options="operators"
                @open="GetOperators"
                :clearOnSelect="true"
                placeholder="Selecione o Operador"
                label="user_name"
              ></multiselect>
            </div>
            <div class="vpos-special-input">
              <vuejs-datepicker
                input-class="form-control"
                :language="ptPT"
                placeholder="Data de Expiração: 01-12-2020"
                v-model="form.expiry"
                :disabled-dates="disabledDates.disabledDates"
              ></vuejs-datepicker>
            </div>
          </div>
        </div>
        <div class="vp-footer">
          <button class="btn btn-success" @click="GenerateSupervisorCode" :disabled="isRequesting">
            <span v-if="!isRequesting">Gerar</span>
            <div class="loading-dots" v-if="isRequesting">
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
            </div>
          </button>
        </div>
    </modal>
    <modal name="delete-Supervisor">
        <div class="vp-header">
          <span>Pretende remover o Supervisor?</span>
        </div>
        <div class="vp-footer">
          <button class="btn btn-danger mg-bottom" @click="DeleteSupervisorCode" :disabled="isRequesting">
            <span v-if="!isRequesting">Remover</span>
            <div class="loading-dots" v-if="isRequesting">
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
            </div>
          </button>
          <button class="btn btn-outline-primary" @click="hideDeleteSupervisorCode" >
            <span>Cancelar</span>
          </button>
        </div>
    </modal>
    <div>
      <alert :text="$t('alert.success')" v-if="hadSuccess" :message="hadSuccess" type="toast--green"></alert>
      <alert :text="$t('alert.error')" v-if="hadError" :message="hadError" type="toast--red"></alert>
    </div>
  </div>
</template>
<script>
import Table from '@/components/BackOffice/Layouts/Table.vue'
import { pt } from 'vuejs-datepicker/dist/locale'
var state = {
  disabledDates: {
    to: new Date()
  }
}
export default {
  components: {
    Table
  },
  data () {
    return {
      form: {},
      formCreated: {},
      supervisors: [],
      operators: [],
      ptPT: pt,
      hadSuccess: '',
      hadError: '',
      isRequesting: false,
      deleteID: '',
      transaction: {
        operator: {
          'first_name': '',
          'last_name': ''
        }
      },
      cols: [
        { name: 'label', label: 'Etiqueta' },
        { name: 'state', label: 'Estado' },
        { name: 'created_date', label: 'Gerado Em:' },
        { name: 'expiry', label: 'Expira Em:' }
      ],
      pagination: {
        perPage: 10,
        pageable: { pageNumber: 0 },
        isFirstPage: false,
        isLastPage: false
      },
      pageCount: 4,
      disabledDates: state
    }
  },
  computed: {
    PT () {
      return pt
    }
  },
  methods: {
    ChangePage (page) {
      this.pagination.pageable.pageNumber = page
    },
    /*
     *  GetSupervisors: This method will fire a GET request
     *  to fetch the Supervisors and the will store the result
     *  into the orders local state property
     */
    async GetSupervisors (type, sort = '', search = '', size) {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      var sorters = []
      if (sort) {
        sorters.push(sort)
      }
      if (type === 'next') {
        this.pagination.pageable.pageNumber += 1
      }

      if (type === 'prev') {
        this.pagination.pageable.pageNumber -= 1
      }

      // API query options like: sorts and pagination
      let query = ''
      query += `page=${this.pagination.pageable.pageNumber}`
      query += size ? `&size=${size}` : `&size=${this.pagination.perPage}`
      query += `&sort=createdDate,desc`

      try {
        const result = await this.axios.get(`/merchants/${merchantID}/supervisors?${query}`)
        const res = result.data
        const r = res.data
        this.totalElements = r.items_count
        this.Supervisors = r.map(item => {
          item.state = this.$options.filters.requestStatus(item.state)
          item.created_date = this.$options.filters.isDate(item.created_date)
          item.expiry = this.$options.filters.isDate(item.expiry)
          return item
        })
        this.pagination.isFirstPage = res.is_first_page
        this.pagination.isLastPage = res.is_last_page
        this.pageCount = res.total_pages
      } catch (e) {
        this.hadError =
          'Não foi possível carregar os Supervisor. Actualize a página.'
      }
      this.isRequesting = false
    },
    /**
     * GetOperators: This method will fire a GET request and then
     * assign the response data into the state property: form
     */
    async GetOperators () {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      try {
        const result = await this.axios.get(
          `/merchants/${merchantID}/operators`
        )
        const res = result.data
        this.operators = res.data
      } catch (e) {
        this.hadError = 'Não foi possível carregar as informações.'
      }
      this.isRequesting = false
    },
    /**
     * GenerateSupervisorCode: This method will fire a POST request
     */
    async GenerateSupervisorCode () {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      try {
        const result = await this.axios.post(
          `/merchants/${merchantID}/supervisors`, this.form
        )
        this.SupervisorCreated = true
        this.formCreated = result.data
        this.hadSuccess = 'Supervisor gerado com sucesso.'
      } catch (e) {
        this.SupervisorError(e)
      }
      this.hideGenerateSupervisorCode()
      this.isRequesting = false
      this.GetSupervisors()
      setTimeout(() => {
        this.hadSuccess = ''
        this.hadError = ''
      }, 6000)
    },
    /**
     * DeleteSupervisor: This method will fire a DELETE request
     */
    async DeleteSupervisorCode () {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      try {
        await this.axios.delete(
          `/merchants/${merchantID}/supervisors/${this.deleteID}`
        )
        this.hadSuccess = 'Supervisor Removido'
        this.GetSupervisors()
      } catch (e) {
        this.SupervisorError(e)
      }
      this.hideDeleteSupervisorCode()
      this.isRequesting = false
      setTimeout(() => {
        this.hadSuccess = ''
        this.hadError = ''
      }, 6000)
    },
    /**
     * SupervisorError: This method will handle all the errors related
     * to the generate Supervisor process.
     *
     * @param {object} error
     * */
    SupervisorError (error) {
      const defaultErrorMessage = `Não foi possível efetuar esta operação`
      if (error.response) {
        switch (error.response.data[0].code) {
          case 'MERCHANT_ID_DOES_NOT_MATCH_AUTHENTICATED_ACCOUNT':
            this.hadError = 'A conta conectada não possui autorização'
            break
          case 'MERCHANT_WITHOUT_ACTIVE_SUBSCRIPTION':
            this.hadError = 'Comerciante não possui subscrição activa.'
            break
          case 'Supervisor_IS_IN_REQUESTED_STATE':
            this.hadError = 'Supervisor está em estado de SOLICITADO'
            break
          case 'Supervisor_NOT_FOUND':
            this.hadError = 'Supervisor não encontrado'
            break
          case 'MERCHANT_NOT_SYNCHRONIZED_WITH_EMIS':
            this.hadError = 'Comerciante não Sincronizado com EMIS'
            break
          default:
            this.hadError = defaultErrorMessage
            break
        }
      } else {
        this.hadError = defaultErrorMessage
      }
    },
    OpenGenerateSupervisorCode () {
      this.$modal.show('generate-Supervisor')
    },
    hideGenerateSupervisorCode () {
      this.$modal.hide('generate-Supervisor')
      this.SupervisorCreated = false
    },
    OpenDeleteSupervisorCode (id) {
      this.$modal.show('delete-Supervisor')
      this.deleteID = id
    },
    hideDeleteSupervisorCode () {
      this.$modal.hide('delete-Supervisor')
      this.SupervisorCreated = false
    }
  },
  created () {
    this.userName = localStorage.getItem('vpos_body_name') ? localStorage.getItem('vpos_body_name') : 'Kevin Durant'
    this.GetSupervisors()
  }
}
</script>
<style style="scss" scoped>
.mg-bottom{
  margin-bottom: 20px;
}

.vdp-datepicker{
  width: 100%;
}

.Supervisor-first-input{
  margin-bottom: 20px;
}

.red-note{
  color: red;
  font-size: 14px;
  text-align: center;
}
</style>
