<template>
  <div>
    <div class="main-header">
      <div class="main-header__heading">
        <bread-crumbs></bread-crumbs>
      </div>
    </div>
    <div class="container">
      <Table
          :cols="cols"
          :data="operators"
          title="Clientes"
          :searchMethod="GetOperators"
          :pagination="pagination"
          :paginationMethod="GetOperators"
          :sortMethod="GetOperators"
          :changePage="changePage"
          resource="operators"
          :hasEdit="true"
          editRoute="RefundCodes"
          :pageCount="pageCount"
          :hasFilter="false"
        ></Table>
    </div>
  </div>
</template>
<script>
import Table from '@/components/BackOffice/Layouts/Table.vue'

export default {
  components: {
    Table
  },
  data () {
    return {
      userName: '',
      operators: [],
      hadError: '',
      isRequesting: false,
      form: {},
      pageCount: 4,
      x: window.matchMedia('(max-width: 480px)'),
      y: window.matchMedia('(max-width: 1024px)'),
      z: window.matchMedia('(max-width: 1500px)')
    }
  },
  computed: {
    cols () {
      if (this.x.matches) { // If media query matches
        return [
          { name: 'user_name', label: this.$t('operators.table.labels.username') },
          // { name: 'bank_name', label: this.$t('operators.table.labels.bankName') },
          { name: 'state', label: this.$t('operators.table.labels.state') }
        ]
      } else {
        return [
          { name: 'user_name', label: this.$t('operators.table.labels.username') },
          { name: 'bank_name', label: this.$t('operators.table.labels.bankName') },
          { name: 'state', label: this.$t('operators.table.labels.state') }
        ]
      }
    },
    pagination () {
      if (this.x.matches) { // If media query matches
        return {
          perPage: 7,
          pageable: { pageNumber: 0 },
          isFirstPage: false,
          isLastPage: false
        }
      } else {
        return {
          perPage: 10,
          pageable: { pageNumber: 0 },
          isFirstPage: false,
          isLastPage: false
        }
      }
    }
  },
  methods: {
    SelectImage (file) {
      this.form.file = file
    },
    /*
     *  GetOperators: This method will fire a GET request
     *  to fetch the subscriptions and the will store the result
     *  into the orders local state property
     */
    async GetOperators (type, sort = '', search = '', size) {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      var sorters = []
      if (sort) {
        sorters.push(sort)
      }
      if (type === 'next') {
        this.pagination.pageable.pageNumber += 1
      }

      if (type === 'prev') {
        this.pagination.pageable.pageNumber -= 1
      }

      // API query options like: sorts and pagination
      let query = ''
      query += `page=${this.pagination.pageable.pageNumber}`
      query += size ? `&size=${size}` : `&size=${this.pagination.perPage}`
      query += `&sort=createdDate,desc`

      try {
        const result = await this.axios.get(`/merchants/${merchantID}/operators?${query}`)
        const res = result.data
        const r = res.data
        this.totalElements = r.items_count
        this.operators = r.map(item => {
          item.bank_name = item.subscription.bank.name
          item.state = this.$options.filters.requestStatus(item.subscription.state)
          return item
        })
        this.pagination.isFirstPage = res.is_first_page
        this.pagination.isLastPage = res.is_last_page
        this.pageCount = res.pages_count
      } catch (e) {
        this.hadError =
          'Não foi possível carregar as subscrições. Actualize a página.'
      }
      this.isRequesting = false
    },
    changePage (page) {
      this.pagination.pageable.pageNumber = page
    }
  },
  created () {
    this.userName = localStorage.getItem('vpos_body_name') ? localStorage.getItem('vpos_body_name') : 'Kevin Durant'
    this.GetOperators()
  }
}
</script>
<style style="scss" scoped></style>
