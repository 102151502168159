<template>
  <div class="vpos-container">
    <div class="vpos-content">
      <div class="box-login">
        <div class="box-login-header">
          <img src="../../../assets/images/icons/vPOS-logo.svg" alt="">
        </div>
        <div class="box-login-body" v-if="!isSuccess">
          <div >
            <div class="col">
              <div class="form-group" >
                  <div class="col-md-12">
                    <input type="text"
                    class="form-control"
                    id="user-email"
                    name="method"
                    placeholder="Email"
                    v-model="email"/>
                  </div>
                </div>
            </div>
            <div class="col side-login">
              <button class="vpos-btn success" @click="ResetPassword">
                <span v-if="!isRequesting">{{ $t('recoveryPassword.recovery') }}</span>
                <div class="loading-dots" v-if="isRequesting">
                  <div class="loading-dots--dot"></div>
                  <div class="loading-dots--dot"></div>
                  <div class="loading-dots--dot"></div>
                </div>
              </button>
            </div>
            <div class="col side-login">
              <router-link :to="{name: 'Login'}" tag="button" class="vpos-btn btn-outline-primary">
                <span>{{ $t('recoveryPassword.cancel') }}</span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="request-message" v-else>
          <div v-if="hadSuccess">
            <h3>{{ $t('recoveryPassword.alert.success') }}</h3>
            <img src="../../../assets/images/icons/success.svg" alt="">
            <p>{{ hadSuccess }}</p>
          </div>
          <div v-if="hadError">
            <h3>{{ $t('recoveryPassword.alert.error') }}</h3>
            <img src="../../../assets/images/icons/error.svg" alt="">
            <p>{{ hadError }}</p>
          </div>
        </div>
        <button v-if="hadSuccess || hadError" @click="goBack" class="vpos-btn btn-outline-primary back-btn">
          {{ $t('recoveryPassword.back') }}
        </button>
      </div>
      <div class="lang-choose">
        <menu-language></menu-language>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import MenuLanguage from '@/components/BackOffice/Layouts/MenuLanguage.vue'

export default {
  name: 'Login',
  components: {
    'menu-language': MenuLanguage
  },
  data () {
    return {
      isRequesting: false,
      isSuccess: false,
      form: {},
      email: '',
      hadError: '',
      hadSuccess: ''
    }
  },
  computed: {
    ...mapGetters('Admins', ['isLoggedIn'])
  },
  methods: {
    /**
     *  ResetPassword: This method will request all the providers available
     *  and store the response in the state property: providers.
     */
    async ResetPassword () {
      this.isRequesting = true
      var merchantUsername = this.email
      this.form.method = 'EMAIL'
      try {
        await this.axios.post(`/merchants/accounts/${merchantUsername}/passwords`, this.form)
        this.hadSuccess = 'Enviamos um email para recuperar a senha'
        this.isSuccess = true
      } catch (e) {
        this.isSuccess = true
        this.HangleLoginErrors(e)
      }
      this.isRequesting = false
    },
    /**
     * HangleLoginErrors: It is a helper method that will
     * handle all the errors regarding the Login method.
     */
    HangleLoginErrors (error) {
      // Error messages
      const defaultErrorMessage = `Não foi possível realizar esta operação.`
      const notFoundError = 'Comerciante não encontrado.'

      // Check for the error response if there's any
      if (error.response) {
        switch (error.response.data[0].code) {
          case 'MERCHANT_ACCOUNT_NOT_FOUND':
            this.hadError = notFoundError
            break
          default:
            this.hadError = defaultErrorMessage
        }
      } else {
        this.hadError = defaultErrorMessage
      }
    },
    goBack () {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  },
  created () {
    // Check if the user is logged in, if so, redirect to the
    // admin area: Dashboard.
    if (this.isLoggedIn) {
      this.$router.push({ name: 'Dashboard' })
    }
  }
}
</script>
<style lang="scss" scoped></style>
