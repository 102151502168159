<template>
  <div>
    <div class="main-header">
      <div class="main-header__heading">
        <bread-crumbs></bread-crumbs>
      </div>
      <div class="col col-12 head-info">
        <dropdown>
          <template slot="btn">{{ $t('transactions.exportBtn') }}</template>
          <template slot="icon"></template>
          <template slot="body">
            <download-excel
              v-if="isRequestingDoc"
              class="btn btn-white"
              :data="json_data"
              :fields="json_fields"
              type="csv"
              :name="filename + '.csv'"
            >
              CSV <i class="fas fa-file-csv"></i>
            </download-excel>
            <download-excel
              v-if="isRequestingDoc"
              class="btn btn-white"
              :data="json_data"
              :fields="json_fields"
              type="xls"
              :name="filename + '.xls'"
            >
              EXCEL <i class="fas fa-file-excel"></i>
            </download-excel>
          </template>
        </dropdown>
      </div>
    </div>
    <div class="container">
      <Table
          :cols="cols"
          :data="transactions"
          title="Transacções"
          :searchMethod="GetTransactions"
          :pagination="pagination"
          :paginationMethod="GetTransactions"
          :sortMethod="GetTransactions"
          :changePage="changePage"
          resource="transactions"
          editRoute="EditTransitions"
          :pageCount="pageCount"
          :detailMethod="openDetail"
          :openFilter="openFilter"
          :filter="filter"
        ></Table>
    </div>
    <modal name="get-transition">
        <div class="top-right">
          <i class="fas fa-times" @click="$modal.hide('get-transition')"></i>
        </div>
        <div class="vp-body vpos-details-modal">
          <div class="vpos-details">
            <p>{{$t('transactions.modal.type')}}</p>
            <span>{{ $t(transaction.type) }}</span>
          </div>
          <div class="vpos-details">
            <span class="money">{{transaction.amount}}</span>
          </div>
          <div class="vpos-details">
            <p>{{$t('transactions.modal.customer')}}</p>
            <span>{{ transaction.customer }}</span>
          </div>
          <div class="vpos-details">
            <p>{{$t('transactions.modal.creationDate')}}</p>
            <span>{{ transaction.created_date }}</span>
          </div>
          <div class="vpos-details">
            <p>{{$t('transactions.modal.provider')}}</p>
            <div class="img-detail">
              <img v-if="!transaction.provider" src="../../../assets/images/No_image_available.png" />
              <img v-else src="../../../assets/images/logo/Logo_MCX_Express_Provider_White.png" />
            </div>
          </div>
          <div class="vpos-details">
            <p>{{$t('transactions.modal.operatorName')}}</p>
            <span>{{ transaction.operator['first_name'] }} {{ transaction.operator['last_name'] }}</span>
          </div>
          <div class="vpos-details">
            <div class="vpos-details-status radius"
            :class="{'is-success-box': 'filters.transactionsStatus.accepted' === transaction.status,
              'is-warning-box': 'filters.transactionsStatus.new' === transaction.status,
              'is-primary-box': 'filters.transactionsStatus.initial' === transaction.status,
              'is-danger-box': 'filters.transactionsStatus.rejected' === transaction.status}">
              <i v-for="(trans, index) of transactionStatus" :key="index" v-show="trans.status === transaction.status" :class="trans.class"></i>
              </div>
          </div>
        </div>
    </modal>
    <div class="filter-modal">
      <modal name="filter-transition">
        <div class="top-right">
          <i class="fas fa-times" @click="$modal.hide('filter-transition')"></i>
        </div>
        <div class="vp-header">
          <span>{{$t('transactions.filter.title')}}: </span>
        </div>
        <div class="vp-body vpos-details-modal">
          <div class="">
            <div class="col col-3">
              <label for>{{$t('transactions.filter.transactionsStatus')}}</label>
              <multiselect
                v-model="filter.status"
                :options="options"
                :searchable="false"
                :close-on-select="true"
                :show-labels="false"
                :placeholder="$t('transactions.filter.statusPlaceholder')"
                label="name"
                >
              </multiselect>
            </div>
            <div class="col col-3">
              <label for>{{$t('transactions.filter.operationType')}}</label>
              <multiselect
                v-model="filter.type"
                :options="operationType"
                :searchable="false"
                :close-on-select="true"
                :show-labels="false"
                :placeholder="$t('transactions.filter.operationType')"
                label="name"
                >
              </multiselect>
            </div>
            <div class="col col-3">
              <label for>{{$t('transactions.filter.source')}}</label>
              <multiselect
                v-model="filter.source"
                :options="transactionSource"
                :searchable="false"
                :close-on-select="true"
                :show-labels="false"
                :placeholder="$t('transactions.filter.source')"
                label="name"
                >
              </multiselect>
            </div>
          <div class="">
            <div class="col col-3">
              <label for>{{$t('transactions.filter.telephone')}}</label>
              <div class="vpos-special-input">
                <span class="input-box-flag"><img src="../../../assets/images/icons/ao-flag.png" alt=""/></span>
                <input class="form-field" type="text" v-model="filter.customer" placeholder="9XX XXX XXX" v-mask="'### ### ###'">
              </div>
            </div>
            <div class="col col-3">
              <label for>{{$t('transactions.filter.minValue')}}</label>
              <div class="vpos-special-input">
                <input type="text" v-model="filter.start_amount" placeholder="1500,99">
              </div>
            </div>
            <div class="col col-3">
              <label for>{{$t('transactions.filter.maxValue')}}</label>
              <div class="vpos-special-input">
                <input type="text" v-model="filter.end_amount" placeholder="250.500,54">
              </div>
            </div>
          </div>
          <div class="">
            <div class="col col-2">
              <label for>{{$t('transactions.filter.from')}}</label>
              <vuejs-datepicker
                input-class="date-filter datetime"
                :language="pt"
                placeholder="01-02-2020"
                v-model="filter.created_since"
              ></vuejs-datepicker>
            </div>
            <div class="col col-2">
              <label for>{{$t('transactions.filter.to')}}</label>
              <vuejs-datepicker
                input-class="date-filter datetime"
                :language="pt"
                placeholder="20-11-2020"
                v-model="filter.created_until"
              ></vuejs-datepicker>
            </div>
          </div>
        </div>
        </div>
        <div class="vp-footer">
            <div class="col col-2">
              <button class="btn btn-success" @click="GetTransactions('','','','',filter)" >
                <span v-if="!isRequesting"> {{$t('transactions.filter.filterBtn')}}</span>
                <div class="loading-dots" v-if="isRequesting">
                  <div class="loading-dots--dot"></div>
                  <div class="loading-dots--dot"></div>
                  <div class="loading-dots--dot"></div>
                </div>
              </button>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>
<script>
import Table from '@/components/BackOffice/Layouts/Table.vue'
import { pt } from 'vuejs-datepicker/dist/locale'
import Dropdown from 'bp-vuejs-dropdown'

export default {
  components: {
    Table,
    Dropdown
  },
  props: {
    type: String
  },
  data () {
    return {
      userName: '',
      pt: pt,
      filter: {
        customer: '',
        start_amount: '',
        end_amount: '',
        status: '',
        type: '',
        created_since: '',
        created_until: ''
      },
      transactions: [],
      docTransactions: [],
      hadSuccess: '',
      hadError: '',
      isRequesting: false,
      isRequestingDoc: false,
      transaction: {
        operator: {
          'first_name': '',
          'last_name': ''
        }
      },
      transactionStatus: [
        { status: 'filters.transactionsStatus.accepted', class: 'fas fa-check' },
        { status: 'filters.transactionsStatus.rejected', class: 'fas fa-times' },
        { status: 'filters.transactionsStatus.new', class: 'far fa-clock' },
        { status: 'filters.transactionsStatus.initial', class: 'fas fa-exclamation' }
      ],
      pageCount: 4,
      json_fields: {
        Cliente: 'customer',
        Estado: 'status',
        'Tipo de Operação': 'type',
        Provedor: 'provider',
        Valor: 'amount',
        'Data de Criação': 'created_date'
      },
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          }
        ]
      ],
      x: window.matchMedia('(max-width: 480px)'),
      y: window.matchMedia('(max-width: 1024px)'),
      z: window.matchMedia('(max-width: 1500px)')
    }
  },
  computed: {
    json_data () {
      return this.docTransactions
    },
    filename () {
      var currentdate = new Date()
      var datetime = currentdate.getDate() + '/' + (currentdate.getMonth() + 1) + '/' + currentdate.getFullYear() + '_' + currentdate.getHours() + ':' + currentdate.getMinutes() + ':' + currentdate.getSeconds()
      return datetime
    },
    cols () {
      if (this.x.matches) { // If media query matches
        return [
          { name: 'amount', label: this.$t('transactions.table.labels.amount') },
          { name: 'type', label: this.$t('transactions.table.labels.type') },
          { name: 'status', label: this.$t('transactions.table.labels.status') }
        ]
      } else {
        return [
          { name: 'customer', label: this.$t('transactions.table.labels.customer') },
          { name: 'amount', label: this.$t('transactions.table.labels.amount') },
          { name: 'provider', label: this.$t('transactions.table.labels.provider') },
          { name: 'type', label: this.$t('transactions.table.labels.type') },
          { name: 'source', label: this.$t('transactions.table.labels.source') },
          { name: 'status', label: this.$t('transactions.table.labels.status') }
        ]
      }
    },
    options () {
      return [
        { id: 'ACCEPTED', name: this.$t('transactions.multiselect.transactionsType.accepted') },
        { id: 'REJECTED', name: this.$t('transactions.multiselect.transactionsType.rejected') },
        { id: 'AUTHORIZATION', name: this.$t('transactions.multiselect.transactionsType.authorization') },
        { id: 'CANCELATION', name: this.$t('transactions.multiselect.transactionsType.cancelation') },
        { id: 'NEW', name: this.$t('transactions.multiselect.transactionsType.new') }
      ]
    },
    operationType () {
      return [
        { id: 'REFUND', name: this.$t('transactions.multiselect.operationType.refund') },
        { id: 'PAYMENT', name: this.$t('transactions.multiselect.operationType.payment') },
        { id: 'AUTHORIZATION', name: this.$t('transactions.multiselect.operationType.authorization') },
        { id: 'CANCELATION', name: this.$t('transactions.multiselect.operationType.cancelation') }
      ]
    },
    transactionSource () {
      return [
        { id: 'API', name: this.$t('transactions.multiselect.source.api') },
        { id: 'APP', name: this.$t('transactions.multiselect.source.app') }
      ]
    },
    pagination () {
      if (this.x.matches) { // If media query matches
        return {
          perPage: 7,
          pageable: { pageNumber: 0 },
          isFirstPage: false,
          isLastPage: false
        }
      } else {
        return {
          perPage: 10,
          pageable: { pageNumber: 0 },
          isFirstPage: false,
          isLastPage: false
        }
      }
    }
  },
  methods: {
    changePage (page) {
      this.pagination.pageable.pageNumber = page
    },
    /*
     *  GetTransactions: This method will fire a GET request
     *  to fetch the subscriptions and the will store the result
     *  into the orders local state property
     */
    async GetTransactions (type, sort = '', search = '', size, filter = {}) {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      var sorters = []
      if (sort) {
        sorters.push(sort)
      }

      if (type === 'next') {
        this.pagination.pageable.pageNumber += 1
      }

      if (type === 'prev') {
        this.pagination.pageable.pageNumber -= 1
      }

      var startCurrent = new Date(filter.created_since)
      var endCurrent = new Date(filter.created_until)

      var startY = startCurrent.getFullYear()
      var startM = startCurrent.getMonth()
      var startD = startCurrent.getDate()

      var endY = endCurrent.getFullYear()
      var endM = endCurrent.getMonth()
      var endD = endCurrent.getDate()

      var startDate = new Date(startY, startM, startD, 1)
      var endDate = new Date(endY, endM, endD, 1)

      // API query options like: sorts and pagination
      let query = ''
      query += `page=${this.pagination.pageable.pageNumber}`
      query += size ? `&size=${size}` : `&size=${this.pagination.perPage}`
      // query += sort ? `&sorters=${sorters}` : ''
      query += filter.start_amount ? `&start_amount=${filter.start_amount}` : ''
      query += filter.end_amount ? `&end_amount=${filter.end_amount}` : ''
      query += filter.status ? `&status=${filter.status.id}` : ''
      query += filter.type ? `&type=${filter.type.id}` : ''
      query += filter.source ? `&source=${filter.source.id}` : ''
      query += filter.customer ? `&customer=+244${filter.customer.replace(/\s/g, '')}` : ''
      query += filter.created_since ? `&created_since=${startDate.toISOString()}` : ''
      query += filter.created_until ? `&created_until=${endDate.toISOString()}` : ''
      query += `&sort=createdDate,desc`
      query += (this.type === 'PAYMENT' || this.type === 'REFUND') ? `&type=${this.type}` : ''
      // query += search ? `&search=${search}` : ''

      try {
        const result = await this.axios.get(`/merchants/${merchantID}/transactions?${query}`)
        const res = result.data
        const r = res.data
        this.totalElements = r.items_count
        this.transactions = r.map(item => {
          item.amount = this.$options.filters.toKwanza(item.amount)
          item.amount = this.$options.filters.replaceComa(item.amount)
          item.status = this.$options.filters.transactionsStatus(item.status)
          item.customer = this.$options.filters.formatCellphone(5, 4, '****', item.customer)
          item.type = this.$options.filters.transactionsType(item.type)
          item.provider = this.$options.filters.providerType(item.provider)
          item.source = this.$options.filters.transactionsSource(item.source)
          return item
        })
        this.pagination.isFirstPage = res.is_first_page
        this.pagination.isLastPage = res.is_last_page
        this.pageCount = res.total_pages
      } catch (e) {
        this.hadError =
          'Não foi possível carregar as subscrições. Actualize a página.'
      }
      this.GetDocsTransactions(type, search, filter)
      this.isRequesting = false
    },
    /*
     *  GetTransactions: This method will fire a GET request
     *  to fetch the subscriptions and the will store the result
     *  into the orders local state property
     */
    async GetDocsTransactions (type, search = '', filter = {}) {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')

      var startCurrent = new Date(filter.created_since)
      var endCurrent = new Date(filter.created_until)

      var startY = startCurrent.getFullYear()
      var startM = startCurrent.getMonth()
      var startD = startCurrent.getDate()

      var endY = endCurrent.getFullYear()
      var endM = endCurrent.getMonth()
      var endD = endCurrent.getDate()

      var startDate = new Date(startY, startM, startD, 1)
      var endDate = new Date(endY, endM, endD, 1)

      // API query options like: sorts and pagination
      let query = ''
      query += `page=${0}`
      query += `&size=${999999}`
      query += filter.start_amount ? `&start_amount=${filter.start_amount}` : ''
      query += filter.end_amount ? `&end_amount=${filter.end_amount}` : ''
      query += filter.status ? `&status=${filter.status.id}` : ''
      query += filter.type ? `&type=${filter.type.id}` : ''
      query += filter.source ? `&source=${filter.source.id}` : ''
      query += filter.customer ? `&customer=+244${filter.customer.replace(/\s/g, '')}` : ''
      query += filter.created_since ? `&created_since=${startDate.toISOString()}` : ''
      query += filter.created_until ? `&created_until=${endDate.toISOString()}` : ''
      query += `&sort=createdDate,desc`
      query += (this.type === 'PAYMENT' || this.type === 'REFUND') ? `&type=${this.type}` : ''

      try {
        const result = await this.axios.get(`/merchants/${merchantID}/transactions?${query}`)
        const res = result.data
        const r = res.data
        this.docTransactions = r.map(item => {
          item.amount = this.$options.filters.toKwanza(item.amount)
          item.amount = this.$options.filters.replaceComa(item.amount)
          item.status = this.$t(this.$options.filters.transactionsStatus(item.status))
          item.type = this.$t(this.$options.filters.transactionsType(item.type))
          item.provider = this.$options.filters.providerType(item.provider)
          item.source = this.$options.filters.transactionsSource(item.source)
          return item
        })

        this.isRequestingDoc = true
      } catch (e) {
        this.hadError =
          'Não foi possível carregar as subscrições. Actualize a página.'
      }
      this.isRequesting = false
    },
    /**
     * GetTransaction: This method will fire a GET request and then
     * assign the response data into the state property: form
     */
    async GetTransaction (id) {
      this.isRequesting = true
      var merchantID = localStorage.getItem('vpos_body_merchant_id')
      try {
        const result = await this.axios.get(
          `/merchants/${merchantID}/transactions/${id}`
        )
        this.transaction = result.data
        this.transaction.created_date = this.$options.filters.isDate(this.transaction.created_date)
        this.transaction.status = this.$options.filters.transactionsStatus(this.transaction.status)
        this.transaction.type = this.$options.filters.transactionsType(this.transaction.type)
        this.transaction.customer = this.$options.filters.formatCellphone(5, 4, '****', this.transaction.customer)
        this.transaction.provider = this.$options.filters.providerType(this.transaction.provider)
        this.transaction.amount = this.$options.filters.toKwanza(this.transaction.amount)
        this.transaction.amount = this.$options.filters.replaceComa(this.transaction.amount)
      } catch (e) {
        this.hadError = 'Não foi possível carregar as informações.'
      }
      this.isRequesting = false
    },
    showTransaction () {
      this.setTransaction()
      this.$modal.show('get-transition')
    },
    openFilter () {
      this.$modal.show('filter-transition')
    },
    openDetail (id) {
      this.setTransaction()
      this.GetTransaction(id)
      this.showTransaction()
    },
    setTransaction () {
      this.transaction = {
        type: '',
        amount: '',
        customer: '',
        provider: '',
        operator: {
          first_name: 'unknow',
          last_name: ''
        }
      }
    }
  },
  created () {
    this.userName = localStorage.getItem('vpos_body_name') ? localStorage.getItem('vpos_body_name') : 'Kevin Durant'
    this.GetTransactions()
  }
}
</script>
<style style="scss" scoped>

.head-info .btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.head-info .btn i{
  margin-left: 6px;
}

.col {
  margin-right: 0;
}

.filter-modal .vp-footer{
  padding: 0;
  display: flex;
  justify-content: center;
}
</style>
