<template>
  <header class="header">
    <span class="clock">{{timer}}</span>
    <div class="header__avatar header-user-name" @click="showBobbly = !showBobbly" v-click-outside="hide">{{initials}}</div>
    <div class="speech-bubble" :class="{ 'show-bobbly' : !showBobbly}" >
        <div class="bubble-header">
          <span>{{merchantName}}</span>
          <!-- <div @click="DoLogout" class="sidenav__list__item-out"><i class="fas fa-sign-out-alt" aria-hidden="true"></i><span>{{$t('navigationDrawer.menu.subMenu6')}}</span></div> -->
        </div>
        <div class="bubble-body">
          <div class="bubble-image">
            {{initials}}
          </div>
          <div class="bubble-details">
            <!-- <span class="email">nextbss@next.co.ao</span> -->
            <router-link :to="{name: 'EditProfile'}" tag="span" class="bubble-link">{{$t('header.edit')}}</router-link>
            <router-link :to="{name: 'AlterPassword'}" tag="span" class="bubble-link">{{$t('header.change')}}</router-link>
          </div>
        </div>
    </div>
  </header>
</template>
<script>

// import Breadcrumbs from '@/components/BackOffice/Layouts/Breadcrumbs.vue'
import ClickOutside from 'vue-click-outside'
import { mapActions } from 'vuex'

export default {
  name: 'vpos-header',
  components: {
    // 'bread-crumbs': Breadcrumbs
  },
  data () {
    return {
      showBobbly: true,
      list: [],
      timer: ''
    }
  },
  computed: {
  },
  methods: {
    /*
     * Map all the actions from the Admin module
     */
    ...mapActions('Admins', ['Logout']),
    async DoLogout () {
      this.Logout()
      setTimeout(() => {
        this.$router.push({ name: 'Login' })
      }, 1500)
    },
    hide () {
      this.showBobbly = true
    },
    getNow () {
      const today = new Date()
      const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(today)
      const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(today)
      const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(today)
      const date = `${day}-${month}-${year}`
      const time = today.getHours() + ':' + ((today.getMinutes() < 10 ? '0' : '') + today.getMinutes()) + ':' + ((today.getSeconds() < 10 ? '0' : '') + today.getSeconds())
      const dateTime = date + ' ' + time
      this.timer = dateTime
    }
  },
  mounted: function () {
    setInterval(function () {
      this.getNow()
    }.bind(this), 1000)
  },
  created () {
    this.merchantName = localStorage.getItem('vpos_body_merchant_name') ? localStorage.getItem('vpos_body_merchant_name') : 'Kevin Durant'
    this.userName = localStorage.getItem('vpos_body_name') ? localStorage.getItem('vpos_body_name') : 'Kevin Durant'
    var names = this.userName.split(' ')
    this.initials = names[0].substring(0, 1).toUpperCase()

    if (names.length > 1) {
      this.initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }
  },
  // do not forget this section
  directives: {
    ClickOutside
  }
}
</script>
<style lang="scss" scoped>
.header .clock{
  font-family: 'Open Sans SemiBold';
  color: rgb(114, 112, 112);
  font-size: 15px;
}

.speech-bubble {
  display: none;
}

.speech-bubble span {
  width: 100%;
  height: 25px;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}

.speech-bubble.show-bobbly {
  width: 330px;
  height: auto;
  top: 50px;
  right: 16px;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 2px 6px #00000029;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bubble-header{
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0 0;
  font-family: 'Open Sans Regular';
  font-size: 14px;
}

.sidenav__list__item-out{
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 5px 10px 5px 20px;
  &:hover{
    background-color: rgb(236, 234, 234);
  }
}

.sidenav__list__item-out i{
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidenav__list__item-out span{
  padding-left: 10px;
}

.bubble-body{
  width: 100%;
  height: 150px;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
}

.bubble-image{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Open Sans Bold';
  background: #11559C;
  color: #ffffff;
  font-size: 28px;
}

.bubble-details{
  width: 70%;
}

.bubble-details .username{
  font-family: 'Open Sans SemiBold';
  color: rgb(73, 73, 73);
}

.bubble-details .email{
  font-family: 'Open Sans Regular';
  color: darkgrey;
  font-size: 12px;
}

.bubble-details .bubble-link{
  font-family: 'Open Sans Regular';
  font-size: 13px;
  color: #11559C;
  cursor: pointer;
}
</style>
