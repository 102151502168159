<template>
  <div class="accordion"  >
    <div class="accordion-header" @click="openClose()">
      <span class="accordion-title" :style="[titleColor ? {'color': titleColor} : {'color': titleColor}]">{{title}}</span>
      <i class="arrow" :class=" active ? 'ups' : 'downs'" :style="[titleColor ? {'border': 'solid' + titleColor, 'border-width': '0 3px 3px 0'} : '']" ></i>
    </div>
    <div class="accordion-body" >
      <div class="accordion-content" v-bind:style="{ height: Height }">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    titleColor: {
      type: String,
      default: '#fff'
    }
  },
  data () {
    return {
      active: false,
      Height: '0'
    }
  },
  methods: {
    openClose () {
      if (this.active) {
        this.Height = '0'
      } else {
        this.Height = 'auto'
      }
      this.active = !this.active
    }
  }
}
</script>

<style>
.accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  background-color: #11559C;
  cursor: pointer;
}

.accordion .accordion-header {
  padding: 15px 20px 10px 30px;  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease;
}

.accordion .accordion-header:hover {
  background-color: #2196F3;
}

.accordion .accordion-header span{
    text-align: left;
    font-family: 'Open Sans SemiBold';
    font-size: 13px;
    letter-spacing: 0;
    color: #fff;
    opacity: 1;
    margin-right: 8px;
}

.accordion .accordion-header .fa-angle-down {
  font-size: 20px;
  transition: all 0.3s ease;
  transform: rotate(180deg);
  right: 10%;
  top: 0px;
}

.accordion-title {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.accordion .accordion-body {
  width: 100%;
  text-align: start;
  box-sizing: border-box;
  transition: all 0.3s ease;
  cursor: default;
  padding-left: 14px;
  background-color: #2196F3;
}

.accordion .accordion-body .accordion-title{
    text-align: left;
    font-family: 'Open Sans SemiBold';
    font-size: 18px;
    letter-spacing: 0;
    color: #fff;
    opacity: 1;
    margin-right: 8px;
}

.accordion .accordion-body .accordion-content {
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.accordion.disabled .accordion-body {
  pointer-events: none;
  padding: 0;
  margin-left: 16px;
  opacity: 0;
  max-height: 0px;
  transform: translateY(-20px);
}
</style>
