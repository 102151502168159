export default {
  namespaced: true,
  state: {
    x: window.matchMedia('(max-width: 480px)'),
    y: window.matchMedia('(max-width: 1024px)'),
    z: window.matchMedia('(max-width: 1500px)')
  },
  getters: {},
  mutations: {},
  actions: {}
}
