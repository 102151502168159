import moment from 'moment'

/*
 *  This filters function will be accessed from the
 *  root main.js of the project to initialize it
 *  passing a the Vue instance object as an argument
 */
export default Vue => {
  /**
   * roles
   * @param {string} role
   * @returns {string}
   */
  Vue.filter('roles', role => {
    const labels = {
      SYSTEM_ADMIN: 'Administrador de Sistema',
      SUPER_ADMIN: 'Super Administrador',
      ADMIN: 'Administrador'
    }

    return labels[role]
  })

  /**
   * requestStatus: This filter will format the proposal status
   * ACTIVE, REQUESTED, SUSPENDED, PROCESSING
   * @param {string} status
   */
  Vue.filter('requestStatus', status => {
    switch (status) {
      case 'REQUESTED':
        return 'filters.requestStatus.requested'
      case 'ACTIVE':
        return 'filters.requestStatus.active'
      case 'SUSPENDED':
        return 'filters.requestStatus.suspended'
      case 'PROCESSING':
        return 'filters.requestStatus.processing'
      case 'CREATION_FAILED':
        return 'filters.requestStatus.creationFailed'
      case 'SANDBOX':
        return 'filters.requestStatus.sandbox'
      default:
        return 'N/A'
    }
  })

  /**
   * transactionsStatus: This filter will format the proposal status
   * ACTIVE, REQUESTED, SUSPENDED, PROCESSING
   * @param {string} status
   */
  Vue.filter('transactionsStatus', status => {
    switch (status) {
      case 'NEW':
        return 'filters.transactionsStatus.new'
      case 'INITIAL':
        return 'filters.transactionsStatus.initial'
      case 'ACCEPTED':
        return 'filters.transactionsStatus.accepted'
      case 'REJECTED':
        return 'filters.transactionsStatus.rejected'
      default:
        return 'N/A'
    }
  })

  /**
   * refundCodeStatus: This filter will format the proposal status
   * Activado and Desactivado
   * @param {string} status
   */
  Vue.filter('refundCodeStatus', status => {
    switch (status) {
      case true:
        return 'operators.table.refundCodes.status.active'
      case false:
        return 'operators.table.refundCodes.status.disable'
      default:
        return 'N/A'
    }
  })

  /**
   * refundCodeStatus: This filter will format the proposal status
   * Activado and Desactivado
   * @param {string} status
   */
  Vue.filter('transactionsSource', status => {
    switch (status) {
      case 'API':
        return 'transactions.source.api'
      case 'APP':
        return 'transactions.source.app'
      default:
        return 'N/A'
    }
  })

  /**
   * merchantStatus: This filter will format the proposal status
   * Activado and Desactivado
   * @param {string} status
   */
  Vue.filter('merchantStatus', status => {
    switch (status) {
      case 'ACTIVE':
        return 'editProfile.statusActive'
      case 'DISABLED':
        return 'editProfile.statusDisable'
      default:
        return 'N/A'
    }
  })

  /**
   * transactionsStatus: This filter will format the proposal status
   * ACTIVE, REQUESTED, SUSPENDED, PROCESSING
   * @param {string} status
   */
  Vue.filter('transactionsType', status => {
    switch (status) {
      case 'PAYMENT':
        return 'transactions.type.payment'
      case 'REFUND':
        return 'transactions.type.refund'
      case 'AUTHORIZATION':
        return 'transactions.type.authorization'
      case 'CANCELATION':
        return 'transactions.type.cancelation'
      default:
        return 'N/A'
    }
  })

  /**
   * providerType: This filter will format the proposal status
   * ACTIVE, REQUESTED, SUSPENDED, PROCESSING
   * @param {string} status
   */
  Vue.filter('providerType', status => {
    switch (status) {
      case 'EMIS_GPO':
        return 'Emis GPO'
      default:
        return 'N/A'
    }
  })

  /*
   *   Convert to kwanza
   */
  Vue.filter('toKwanza', (value) => {
    value = parseFloat(value)
    return (value.toLocaleString('ao-PT').indexOf('.') > -1) ? value.toLocaleString('ao-PT') + ' AOA' : value.toLocaleString('ao-PT') + '.00' + ' AOA'
  })

  /*
   *  Replace coma with point
   */
  Vue.filter('replaceComa', (value) => {
    value = value.toString()
    return value.replace(/[.,]/g, function (x) {
      return x === ',' ? '.' : ','
    })
  })

  /*
   *  Replace point with coma
   */
  Vue.filter('replacePoint', (value) => {
    return value ? value.replace('.', ',') : 0
  })

  /*
   *  Add decimal points
   */
  Vue.filter('decimal', (value) => {
    return value + ',00'
  })

  /*
   *
   * isDate: It formats the date in the following format: LL
   */
  Vue.filter('isDate', date => {
    var stillUtc = moment.utc(date).toDate()
    if (date) {
      return date ? (moment.utc(stillUtc).format('DD/MM/YYYY HH:mm:ss')) : moment().format('LL')
    } else {
      return 'N/A'
    }
  })

  /**
   * numberToKwanza: It formats the value passed as paramater into the Kwanza currency format
   *
   * @param {number} value
   */
  Vue.filter('reduceText', (value, max) => {
    return value.length > max ? `${value.substring(0, max)}...` : value
  })

  /**
   * transactionsStatus: This filter will format the proposal status
   * ACTIVE, REQUESTED, SUSPENDED, PROCESSING
   * @param {string} status
   */
  Vue.filter('monthNumber', num => {
    switch (num) {
      case 1:
        return 'Janeiro'
      case 2:
        return 'Fevereiro'
      case 3:
        return 'Março'
      case 4:
        return 'Abril'
      case 5:
        return 'Maio'
      case 6:
        return 'Junho'
      case 7:
        return 'Julho'
      case 8:
        return 'Agosto'
      case 9:
        return 'Setembro'
      case 10:
        return 'Outubro'
      case 11:
        return 'Novembro'
      case 12:
        return 'Dezembro'
      default:
        return 'N/A'
    }
  })

  Vue.filter('formatCellphone', (start, length, word, content) => {
    var str = content.replace(new RegExp('^(.{' + start + '}).{' + length + '}'), '$1' + word)
    return str.replace('+244', '')
  })
}
