import locales from '@/locales'

const LENGUAGES = Object.keys(locales)

const getCurrentLocale = () => {
  const localeSaved = window.localStorage.getItem('currentLanguage')

  if (localeSaved) return LENGUAGES.includes(localeSaved) ? localeSaved : LENGUAGES[0]

  // remove o '-' do locale
  const lang = window.navigator.language.replace('-', '')

  if (lang) return LENGUAGES.includes(lang) ? lang : LENGUAGES[0]

  return LENGUAGES[0]
}

export default getCurrentLocale
