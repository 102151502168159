<template>
  <div class="grid-container">
    <div class="menu-icon" @click="openToggle">
      <i class="fas fa-bars"></i>
    </div>
    <vpos-header></vpos-header>
    <vpos-aside></vpos-aside>
    <vpos-main></vpos-main>
  </div>
</template>
<script>
import VposHeader from '@/components/BackOffice/Layouts/Vpos-Header.vue'
import VposAside from '@/components/BackOffice/Layouts/Vpos-Aside.vue'
import VposMain from '@/components/BackOffice/Layouts/Vpos-Main.vue'

export default {
  components: {
    'vpos-aside': VposAside,
    'vpos-header': VposHeader,
    'vpos-main': VposMain
  },
  data () {
    return {
      isActive: false
    }
  },
  methods: {
    openToggle () {
      var element = document.querySelector('.sidenav')
      element.classList.toggle('active')
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
